import RestService from "./RestService";

export default class TestService extends RestService {
  constructor() {
    super("/api/quiz");
  }

  async getTestByIdLabel(testIdLabel) {
    return this.get(`${this.baseUrl}/getQuiz/${testIdLabel}`);
  }

  async submitAnswer(testIdLabel, answers) {
    return this.post(`${this.baseUrl}/submitAnswer/${testIdLabel}`, answers);
  }
}

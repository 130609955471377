import { Button, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useStores } from "../../hooks/use-stores";
import { ContentPageStyles } from "./styles";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page: {
    position: "relative",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#007BFF",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#007BFF",
    },
  },
  title: {
    fontSize: "32px",
    marginBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  question: {
    display: "flex",
    alignItems: "flex-start",
    gap: "4px",
    margin: "28px 0 0 0",
    "& p": {
      margin: "0",
    },
  },
  answerList: { paddingLeft: "16px", margin: "12px 0" },
  answer: {
    listStyleType: "lower-alpha",
    margin: "6px 0",
    display: "flex",
    alignItems: "flex-start",
    "& p": {
      margin: "0",
    },
  },
  answerSelector: {
    padding: 0,
  },
  answerText: {
    display: "block",
    marginLeft: "8px",
    // position: "relative",
    // top: "1px",
  },
  submitBtnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "12px",
    marginTop: "32px",
  },
  submitBtn: {
    background: "#007BFF",
    color: "white",
  },
  backBtn: {
    background: "#007BFF",
    color: "white",
    display: "block",
    margin: "40px auto",
    [theme.breakpoints.down("xs")]: {
      margin: "28px auto",
    },
    "&:hover": {
      color: "black",
    },
  },
  resultWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  result: {
    width: "100%",
    maxWidth: "300px",
  },
  resultTitle: {
    fontSize: "32px",
    marginTop: "40px",
    marginBottom: "48px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  resultSubTitle: {
    fontSize: "20px",
    marginBottom: "20px",
    paddingBottom: "8px",
    textAlign: "center",
    borderBottom: "1px solid white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  score: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "20px",
  },
}));

const complianceTestIdLabel = "Compliance Test";

function ComplianceTest() {
  const { t } = useTranslation("starfish");
  const classes = useStyles();
  const history = useHistory();
  const { testStore } = useStores();
  const { testInfo, testLoading, submitAnswerResult, submittingAnswer, error } =
    testStore;

  const [answers, setAnswers] = useState([]);

  const loadTest = () => {
    if (!testLoading) {
      void testStore.getTestByIdLabel(complianceTestIdLabel);
    }
  };
  useEffect(() => {
    loadTest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <div className={clsx(classes.flexCenter)}>{error}</div>;
  }

  if (testLoading || !testInfo) {
    return (
      <div className={clsx(classes.flexCenter)}>
        <CircularProgress size={40} />
      </div>
    );
  }

  if (testInfo.isAnswered || submitAnswerResult?.isAnswered) {
    return (
      <>
        <h2 className={clsx(classes.resultTitle)}>
          {t("compliance_test_page.completed")}
        </h2>
        <div className={clsx(classes.resultWrapper, classes.flexCenter)}>
          <div className={clsx(classes.result)}>
            <div>
              <p className={clsx(classes.resultSubTitle)}>
                {t("compliance_test_page.results")}:
              </p>
              <h4 className={clsx(classes.score)}>
                <span>{t("compliance_test_page.correct")}: </span>
                <span>{testInfo.correct}</span>
              </h4>
              <h4 className={clsx(classes.score)}>
                <span>{t("compliance_test_page.total")}: </span>
                <span>{testInfo.total}</span>
              </h4>
              <h4 className={clsx(classes.score)}>
                <span>{t("compliance_test_page.score")}: </span>
                <span>
                  {testInfo.correct}/{testInfo.total}
                </span>
              </h4>

              <Button
                variant="contained"
                type={"button"}
                onClick={() => {
                  history.goBack();
                }}
                className={clsx(classes.backBtn)}
              >
                {t("compliance_test_page.back")}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  const handleSelectRadio = ({ questionId, targetAnswerId }) => {
    let updatedAnswers;

    if (!answers.find((item) => item.questionId === questionId)) {
      updatedAnswers = [
        ...answers,
        {
          questionId,
          answerIds: [targetAnswerId],
        },
      ];
    } else {
      updatedAnswers = answers.map((item) =>
        item.questionId === questionId
          ? {
              questionId: item.questionId,
              answerIds: [targetAnswerId],
            }
          : item
      );
    }

    setAnswers(updatedAnswers);
  };

  const notAllQuestionsAnswered =
    answers.length !== testInfo.questions.length ||
    !!answers.find((item) => !item.answerIds || item.answerIds?.length === 0);

  const handleSubmit = async () => {
    if (!notAllQuestionsAnswered) {
      void testStore.submitAnswer({
        testIdLabel: complianceTestIdLabel,
        answers,
      });
    }
  };

  return (
    <div className={clsx(classes.page)}>
      <h2 className={clsx(classes.title)}>
        {testInfo.title || complianceTestIdLabel}
      </h2>
      {testInfo.questions.map((question, index) => (
        <div key={question.questionId}>
          <h4 className={clsx(classes.question)}>
            <span>#{index + 1}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: question.questionText,
              }}
            />
          </h4>

          <RadioGroup
            className={clsx(classes.answerList)}
            onChange={(e) => {
              handleSelectRadio({
                questionId: question.questionId,
                targetAnswerId: e.target.name,
              });
            }}
          >
            {question.answers?.map((answer) => (
              <FormControlLabel
                value={answer.answerText}
                className={clsx(classes.answer)}
                control={
                  <Radio
                    disabled={submittingAnswer}
                    className={clsx(classes.answerSelector)}
                    name={answer.answerId}
                  />
                }
                label={
                  <span
                    className={clsx(classes.answerText)}
                    dangerouslySetInnerHTML={{
                      __html: answer.answerText,
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        </div>
      ))}

      <div className={classes.submitBtnWrapper}>
        {submittingAnswer && <CircularProgress size={40} />}
        <Button
          variant="contained"
          type={"button"}
          onClick={handleSubmit}
          disabled={notAllQuestionsAnswered || submittingAnswer}
          className={clsx(classes.submitBtn)}
        >
          {t("compliance_test_page.submit")}
        </Button>
      </div>
    </div>
  );
}

export default observer(ComplianceTest);

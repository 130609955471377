import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { useStores } from "../hooks/use-stores";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LocalMallOutlined from "@material-ui/icons/LocalMallOutlined";
import DateRangeOutlined from "@material-ui/icons/DateRangeOutlined";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShareIcon from "@material-ui/icons/Share";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({

    divider: {
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    icon: {
        color: "white",
        minWidth: 30
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default observer(({ isMember, identityVerificationStatus, openPage: doOpenPage, resetLibrarySearch, onLogout, forceTag, onForceTagApplied, showBack = false, onClose }) => {

    const { t } = useTranslation();
    const [membersOpen, setMembersOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [eventsOpen, setEventsOpen] = useState(false);
    const [showAlreadyMemberModal, setShowAlreadyMemberModal] = useState(false);
    const classes = useStyles();
    const {commonStore} = useStores();
    const history = useHistory();

    const { supportStore } = useStores();
    const { supportInfo, supportInfoLoading } = supportStore;

    useEffect(() => {
        void supportStore.loadSupportInfo();
    }, []);

    useEffect(() => {

        if (forceTag === "profile") {
            setProfileOpen(true);
        }
        if (forceTag === "events") {
            setEventsOpen(true);
        }


        if (onForceTagApplied)
            onForceTagApplied();

    }, [forceTag]);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isVerified = React.useMemo(() => {
        return ["APPROVED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);


    const checkUnverified = () => {
        if ( isUnverified || isVerified) {
            openPage("/member/enroll");
        }
        else
            commonStore.showConfirm(t("starfish:home.account_verification_failed")
                , t("starfish:home.verify_account")
                , t("starfish:home.contact_support")
                , () => {
                    history.push('/profile/help')
                }
            )

    }


    const handleBecomeMember = () => {
        if (isMember) {
            setShowAlreadyMemberModal(true);
        }  else if ( !supportInfo.isUserBoughtBookKits ){
            commonStore.showConfirm( t("global:becomeAMemberBuyBooks")
                ,  "OK"
                , t("global:becomeAMember"),  ()=>{}, null);
        }
        else {
            checkUnverified();
        }
    };

    const handleLogout = ()=>{
        onClose()
        onLogout()
    }

    const hideAlreadyMemberModal = () => {
        setShowAlreadyMemberModal(false);
    };

    const openPage = ( target, close=true)=>{
        if( close )
            onClose()
        doOpenPage(target)
    }



    return <><List>
        {showBack && <ListItem button onClick={onClose} dense>
            <ListItemIcon className={classes.icon}><KeyboardArrowLeft /></ListItemIcon>
        </ListItem>}
        {isMember && <>
            <ListItem button onClick={e => { if (!membersOpen) openPage("/members", false ); setMembersOpen(!membersOpen); }}>
                <ListItemIcon className={classes.icon}><PeopleOutline /></ListItemIcon>
                <ListItemText primary={t("membersOnly")} />
                {membersOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={membersOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} onClick={e => { openPage("/members"); }}>
                        {t("fullOffice")}
                    </ListItem>

                    <ListItem button className={classes.nested} onClick={e => { openPage("/members/dashboard"); }}>
                        {t("dashboard")}
                    </ListItem>
                </List>
            </Collapse>
            <Divider className={classes.divider} />
        </>}
        <ListItem button onClick={e => { openPage("/"); }}>
            <ListItemIcon className={classes.icon}><HomeOutlined /></ListItemIcon>
            <ListItemText primary={t("home")} />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem button onClick={e => { (resetLibrarySearch && resetLibrarySearch()); openPage("library"); }}>
            <ListItemIcon className={classes.icon}><VideoLibrary /></ListItemIcon>
            <ListItemText primary={t("library")} />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem button onClick={e => { openPage("wallet"); }}>
            <ListItemIcon className={classes.icon}><AccountBalanceOutlined /></ListItemIcon>
            <ListItemText primary={t("wallet")} />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem button onClick={e => { openPage("shop"); }}>
            <ListItemIcon className={classes.icon}><LocalMallOutlined /></ListItemIcon>
            <ListItemText primary={t("shop")} />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem button onClick={e => { setEventsOpen(!eventsOpen); }}>
            <ListItemIcon className={classes.icon}><DateRangeOutlined /></ListItemIcon>
            <ListItemText primary={t("events")} />
            {eventsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={eventsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={onClose} component={Link} to={"/events/schedule"}>
                    {t("eventSchedule")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/events/purchase_tickets"); }}>
                    {t("purchaseTickets")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/events/seminar_webinar"); }}>
                    {t("seminarWebinar")}
                </ListItem><ListItem button className={classes.nested} onClick={e => { openPage("/events/pay-per-view"); }}>
                    {t("payPerView")}
                </ListItem>
            </List>
        </Collapse>
        <Divider className={classes.divider} />
        <ListItem button onClick={e => { setProfileOpen(!profileOpen); }}>
            <ListItemIcon className={classes.icon}><AccountCircleOutlined /></ListItemIcon>
            <ListItemText primary={t("profile")} />
            {profileOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={profileOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/personalinfo"); }}>
                    {t("personalInfo")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/shippinginfo"); }}>
                    {t("shippingInfo")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/billinginfo"); }}>
                    {t("billingInfo")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/registrationinfo"); }}>
                    {t("registrationInfo")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/security"); }}>
                    {t("passwordAndSecurity")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/subscriptions"); }}>
                    {t("subscriptions")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/transactions"); }}>
                    {t("transactionHistory")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/policies"); }}>
                    {t("policiesAndProcedures")}
                </ListItem>
                <ListItem button className={classes.nested} onClick={e => { openPage("/profile/help"); }}>
                    {t("helpAndSupport")}
                </ListItem>
                {supportInfoLoading !== null && <>
                    {supportInfoLoading
                        ? null
                        : <>
                            { (supportInfo?.isMemberEnrollmentAvailable || isMember)
                                && <>
                                    <ListItem button className={classes.nested} onClick={handleBecomeMember}>
                                        {t("becomeAMember")}
                                    </ListItem>
                                </>
                            }
                        </>
                    }
                </>}
                <ListItem button className={classes.nested} onClick={handleLogout}>
                    {t("logout")}
                </ListItem>
            </List>
        </Collapse>
        {!isMember
            ? null
            : <>
                <Divider className={classes.divider} />
                <ListItem button onClick={e => { openPage("share"); }}>
                    <ListItemIcon className={classes.icon}><ShareIcon /></ListItemIcon>
                    <ListItemText primary={t("share")} />
                </ListItem>
            </>
        }
        <Divider className={classes.divider} />
        <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.icon}><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={t("logout")} />
        </ListItem>
    </List>
        <Dialog open={showAlreadyMemberModal} maxWidth="xs" fullWidth>
            <DialogTitle>{t("already_a_member.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("already_a_member.description")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={hideAlreadyMemberModal} color="primary">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>

    </>;
});



import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import every from "lodash/every";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import Slider from "react-slick";


import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Fab,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Select,
    IconButton,
    ListItemSecondaryAction,
    Typography,
    CircularProgress,
    Paper,
    Link,
    ButtonGroup,
    Menu,
    MenuItem,
    Checkbox,
    ListItem,
    ListItemText,
    AppBar,
    Tabs,
    Tab,
    FilledInput,
    InputAdornment,
    OutlinedInput,
    Avatar,
    Popover,
    List,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, FormControlLabel
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Progress from "../../components/Progress";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/Help";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";
import {starFishColor} from "../../components/styles";
import {useTranslation} from "react-i18next";
import mdResize from "../../themes/mdResize";
import OwlCarousel from 'react-owl-carousel2';
import moment from "moment";
import UserSubscriptions from "./controls/userSubscriptions";
import {TreeView, TreeItem} from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import Thermometer from "./controls/thermometer";
import BonusChart from "./controls/BonusChart";
import UplineSearch from "./controls/UplineSearch";
import {formatNumberOrZero} from "../../utils/helpers";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CustomerRequirements from "./controls/CustomerRequirements";


const ITEM_HEIGHT = 48;
const LeaderPV = 8000;

function checkRadioFilters(radioFilters) {
    for (let i = 0; i < radioFilters.length; i++) {
        if (radioFilters[i].filter) {
            if (radioFilters[i].filter === "yes" && !radioFilters[i].value)
                return true
            if (radioFilters[i].filter === "no" && radioFilters[i].value)
                return true
        }
    }
    return false;
}

function isPersonHidden(person, myTeamsStore) {
    const {
        hideMTC, hideTerminated, ranksFilter, show2LOnly, percentNextRank, subscriptionsFilter
        , coreFilter, crFilter, pboFilter, convFilter, semFilter,
    } = myTeamsStore

    if (ranksFilter && ranksFilter.length > 0) {
        if (!ranksFilter.includes(person.PVRankID))
            return true;
    }
    if (show2LOnly) {
        if (person.NumTeams < 2 && !person.Show2LegsAuto)
            return true;
    }


    if (percentNextRank > 0) {
        if (person.PercentNextRank * 100 < (100 - percentNextRank))
            return true;
    }

    if (checkRadioFilters([
        {filter: coreFilter, value: person.IsCore4},
        {filter: crFilter, value: person.CustomerRequirementsMet},
        {filter: pboFilter, value: person.IsPBO},
        {filter: convFilter, value: person.ConvTickets > 0},
        {filter: semFilter, value: person.SeminarTickets > 0}
    ]))
        return true;


    if (subscriptionsFilter && subscriptionsFilter.length > 0) {
        if (!every(subscriptionsFilter.map(x => {
            return person[x
            .replace("suRR", "PkgRascal")
            .replace("suAppSupPlus", "PkgAppSuperPlus")
            .replace("su", "Pkg")] > 0
        })))
            return true;
    }


    if ((hideMTC && person.mtcTrans === "mtcTrans") || (hideTerminated && person.mtcTrans === "term"))
        return true;
}

function GetPersonNumberDashboard(pv, shorten = true, fractions = 0) {
    if ( pv == null || isNaN(pv) )
        pv = 0;
    if (shorten) {
        if (pv >= 100000) {
            return Math.round(pv / 1000.0, 0) + "K"
        } else {
            return formatNumber(Math.floor(pv), fractions)
        }
    } else {
        return formatNumber(Math.floor(pv), fractions)
    }
}


function GetPersonNumber(pv, teamLeaders) {
    if (pv >= LeaderPV) return teamLeaders
    else return pv
}

function formatNumber(v, fractions) {
    return v.toLocaleString("en-US", {
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
    })
}




const useStyles = makeStyles((theme) => ({
    losBody: {
        position: "relative",
        background: "white",
        [theme.breakpoints.down('xs')]: {
            margin: "0 10px",
        }
    },
    ulLOS: {
        marginLeft: 0,
        paddingLeft: 0,
        marginBottom: 20,
        listStyle: "none",
    },
    listGroupItem: {

        position: "relative",
        display: "block",
        marginBottom: -1,
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        padding: "2px 0",

        "&:first-child": {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
        },
    },
    listGroupItemA: {
        background: `url(/img/shade.png) repeat-y #e6e6e`,
        zIndex: 1
    },
    listGroupItemN: {
        background: `url(/img/shade.png) repeat-y`
    },
    p: {
        paddingTop: 5,
        lineHeight: "12px",
        minHeight: 61,
        boxSizing: "content-box"
    },
    smp: {
        float: "left",
    },
    smc: {
        marginLeft: 42
    },

    span: {
        position: "absolute",
        display: "block",
        width: 40,
        textAlign: "center",
        overflow: "hidden",
        bottom: 5,
        left: -2,
        color: "#000000"
    },
    hidden: {
        display: "none"
    },
    bracket: {
        fontFamily: "Arial",
        fontSize: 20,
        lineHeight: "20px",
        color: "white",
        position: "absolute",
        bottom: -12,
        left: -25,
        display: "block",
        width: 23,
        textAlign: "right"
    },
    accordionSummary: {
        ...starFishColor(theme, "backgroundColor"),
        "&.Mui-expanded": {
            margin: 0,
            minHeight: 50
        },
        "& .MuiAccordionSummary-content": {
            margin: "11px 0"
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "11px 0"
        }
    },

    heading: {
        padding: 0,
        margin: 0
    },
    panelWrapper:{
        padding: "0 5px"
    },
    panel: {
        minWidth: 250,
        backgroundColor: "#535353",
        cursor: "pointer",

    },

    highlight: {
        backgroundColor: "#878787",
        cursor: "initial"
    },

    teamMemberTitle: {
        textTransform: "uppercase",
        backgroundColor: "#000000",
        color: "#ffffff",
        borderRadius: 3,
        display: "flex",
        justifyContent: "center",
        padding: 3
    },
    teamMemberRank: {
        display: "flex",
        justifyContent: "center",
        padding: 3
    },
    navContainerClass: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    navClassPrev: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        width: 30,
        background: "#777777",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    navClassNext: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: 30,
        background: "#777777",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    stageOuterClass: {
        position: "relative",
        marginLeft: 40,
        marginRight: 40,
        overflow: "hidden"
    },

    appBar: {
        backgroundColor: "black",
        top: 58,
        height: 95,
        margin: "0 -20px",
        padding: "0 20px",
        width:"calc( 100% + 40px )"
    },
    toolbar: {
        alignItems: "center",
        height: "100%"
    },

    select: {
        padding: "12px 10px"
    },
    thermometer: {
        minWidth: 320,
        margin: 10
    },
    thermometerBody: {
        padding: 10
    },
    thermometerHeader: {
       // ...starFishColor(theme, "backgroundColor"),
        borderTopRightRadius: 3,
        borderTopLeftRadius: 3,
        padding: 6,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },

    slider:{
        "& .slick-list": {
            margin: "0 40px",
            [theme.breakpoints.down('xs')]: {
                margin: "0 30px",
            }
        }
    },

    primaryIcon:{
        ...starFishColor(theme)
    },

    requirementsHead:{
        ...starFishColor(theme, "backgroundColor"),
    },

    requirementsRow:{
        "&:nth-child(odd) ":{
            backgroundColor: "rgb(51,51,51)",
        },
        "&:nth-child(even) ":{
            fontWeight: "bold",
        },
        "&> td":{
            padding: 5,
            borderBottom: "none"
        }
    },

    requirementsDescription:{
        whiteSpace: "pre-wrap"
    }


}));


function SampleNextArrow(props) {


    const classes = useStyles();
    const { onClick } = props;
    return (
        <div
            className={classes.navClassNext}
            onClick={onClick}
        >
            <i className = "fa fa-chevron-right" > < /i>
        </div>
    );
}

function SamplePrevArrow(props) {

    const classes = useStyles();
    const { onClick } = props;
    return (
        <div
            className={classes.navClassPrev}
            onClick={onClick}
        >  <i className = "fa fa-chevron-left" > < /i></div>
    );
}

function UserPanel({t, PVPeriodID, user}) {
    let details = user.totals || user;
    return <> <Box display="flex" justifyContent="space-between">
        <Typography>{t("TotalPV")}</Typography>
        <Typography>{GetPersonNumberDashboard(user.PV, false)}</Typography>
    </Box>
        {PVPeriodID < 68 && (
            <>
                <Box display="flex" justifyContent="space-between">
                    <Typography>{t("TotalAccess")}</Typography>
                    <Typography>{GetPersonNumberDashboard(details.TotalAccess, false, 2)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography>{t("ProcessedTA")}</Typography>
                    <Typography>{GetPersonNumberDashboard(details.TotalAccessProcessed)}</Typography>
                </Box>
            </>
        )}

        <Box display="flex" justifyContent="space-between">
            <Typography dangerouslySetInnerHTML={{__html: t('MarketingSystem')}}/>
            <Typography>{GetPersonNumberDashboard(details.Training)}/<strong>{GetPersonNumberDashboard(details.TrainingProcessed)}</strong></Typography>
        </Box>

        {PVPeriodID < 68 && (
            <Box display="flex" justifyContent="space-between">
                <Typography>{t("PBO")}</Typography>
                <Typography>{GetPersonNumberDashboard(details.PBO)}</Typography>
            </Box>
        )}


        {PVPeriodID >= 68 && (
            <Box display="flex" justifyContent="space-between">
                <Typography dangerouslySetInnerHTML={{__html: t('SOT')}}/>
                <Typography>{GetPersonNumberDashboard(details.SOT)}/<strong>{GetPersonNumberDashboard(details.SOTProcessed)}</strong></Typography>
            </Box>
        )}

        <Box display="flex" justifyContent="space-between">
            <Typography dangerouslySetInnerHTML={{__html: t('Tickets')}}></Typography>
            <Typography
                fontWeight="bold">{GetPersonNumberDashboard(details.TicketSeminar)}/<strong>{GetPersonNumberDashboard(details.SeminarAttendance)}</strong>
            </Typography>
        </Box>

        {PVPeriodID >= 68 && (
            <Box display="flex" justifyContent="space-between">
                <Typography dangerouslySetInnerHTML={{__html: t('ConventionTickets')}}></Typography>
                <Typography>
                    {GetPersonNumberDashboard(details.TicketConvention)}/<strong>{GetPersonNumberDashboard(details.ConventionAttendance)}</strong>
                </Typography>
            </Box>
        )}

        <Box display="flex" justifyContent="space-between">
            <Typography>{t("Enrollments")}</Typography>
            <Typography>{GetPersonNumberDashboard(details.Enrollments)}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
            <Typography>{t("TeamsDepth")}</Typography>
            <Typography>{GetPersonNumberDashboard(details.EnrollmentsDepth5)}/{GetPersonNumberDashboard(details.EnrollmentsDepth10)}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
            <Typography dangerouslySetInnerHTML={{__html: t('Starfish')}}/>
            <Typography>{GetPersonNumberDashboard(details.Starfish)}/<strong>{GetPersonNumberDashboard(details.StarfishProcessed)}</strong></Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
            <Typography dangerouslySetInnerHTML={{__html: t('Royal')}}/>
            <Typography>{GetPersonNumberDashboard(details.AppSuperPlus)}/<strong>{GetPersonNumberDashboard(details.AppSuperPlusProcessed)}</strong></Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
            <Typography>{t("StarGetter")}</Typography>
            <Typography>{GetPersonNumberDashboard(details.StarGetters)}</Typography>
        </Box>
    </>
}

export default observer(function MyTeam({}) {

    const classes = useStyles();
    const {t} = useTranslation("teams");
    const {commonStore, authStore, myTeamsStore, periodsStore} = useStores();
    const [showFullLOSModule, setShowFullLOSModule] = React.useState(true);
    const [detailedDialogFor, setDetailedDialogFor] = React.useState(null);
    const [showingLegend, setShowingLegend] = React.useState(false);
    const [showingMonthEnd, setShowingMonthEnd] = React.useState(false);

    const [anchorJumpEl, setAnchorJumpEl] = React.useState(null);
    const [anchorTrailEl, setAnchorTrailEl] = React.useState(null);
    const [anchorFilterEl, setAnchorFilterEl] = React.useState(null);
    const [anchorSearchEl, setAnchorSearchEl] = React.useState(null);
    
    
    const handleSearchClick = (event) => {
        setAnchorSearchEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        myTeamsStore.resetSearch()
        setAnchorSearchEl(null);
    };


    const handleJumpClick = (event) => {
        setAnchorJumpEl(event.currentTarget);
    };

    const handleJumpClose = () => {
        setAnchorJumpEl(null);
    };

    const handleTrailClick = (event) => {
        setAnchorTrailEl(event.currentTarget);
    };

    const handleTrailClose = () => {
        setAnchorTrailEl(null);
    };

    const handleFilterClick = (event) => {
        setAnchorFilterEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorFilterEl(null);
    };

    const handleUserSelectFromTrail = (userID) => {
        myTeamsStore.setUser(userID)
        handleTrailClose()
    }

    const handleUserSelectFromJump = (userID) => {
        myTeamsStore.setUser(userID)
        handleJumpClose()
    }


    const handleUserSelectFromSearch = (userID) => {
        handleSearchClose()
        myTeamsStore.setUser(userID)

    }

    const toggleFilter = (e, item, parent) => {
        e.preventDefault()
        myTeamsStore.toggleFilter(item, parent)
    }

    const trailOpen = Boolean(anchorTrailEl);
    const filterOpen = Boolean(anchorFilterEl);
    const jumpOpen = Boolean(anchorJumpEl);
    const searchOpen = Boolean(anchorSearchEl);

    const {
        currentTeam,
        fullTrail,
        ranks,
        dashboardUserInfo,
        myTeamsLoading,
        myTeamsInfo,
        PVPeriodID,
        detailedUserInfo,
        detailedUserLoading,
        modifyingWatch,
        modifyingJump,
        removingJumps,
        jumpList,
        showMonthEnd,
        searchPanelSize

    } = myTeamsStore;
    const {currentUser} = authStore;

    function GetPersonClass(person, size = "Sm") {
        let color = "Gr"
        if (!person.IsEnrolled)
            color = "Bl"
        else if (person.MemberToCustomerTransitioned && person.Terminated)
            color = "Pr Li term"
        else if (person.MemberToCustomerTransitioned)
            color = "Pr Li"
        else if (person.IsExpired)
            color = "Or"
        else if (person.MemberToCustomer)
            color = "Pr"
        else if (!person.CustomerRequirementsMet)
            color = "Re"


        let holding = "Si"

        if (person.PV >= LeaderPV) {
            holding = "Do"
        }
        let wearing = "";
        let hat = "";

        if (PVPeriodID < 68) {
            if (person.OnSubscription) {
                wearing = "Gl"
            }
            if (person.IsPBO) {
                hat = " Ha"
            }
        } else {
            if (person.PkgLT > 0) {
                wearing = " Gl"
            }
            if (person.PkgSOT > 0) {
                hat = " Ha"
            }
        }

        return [size, color, holding, wearing, hat].join(" ")
    }


    useEffect(() => {
        if (currentUser) {
            myTeamsStore.setUser(currentUser.userID, true)
            periodsStore.loadPeriods(currentUser.userID)
            myTeamsStore.loadJumpList()
            myTeamsStore.loadRankList()
        }
    }, [currentUser])

    // const options = {
    //     loop: false,
    //     nav: true,
    //     // navContainerClass: classes.navContainerClass,
    //     // stageOuterClass: classes.stageOuterClass,
    //     // navClass: [classes.navClassPrev, classes.navClassNext],
    //     // navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    //     center: true,
    //     autoplay: false,
    //     dots: false,
    //     mouseDrag: true,
    //     touchDrag: true,
    //     autoWidth:false,
    //     margin:5,
    //     items: 3
    // };

    const decrement = 1;

    const options = {
        infinite: false,
            arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: false,
        // prevArrow: $('#teamPrev'),
        // nextArrow: $('#teamNext'),
        nextArrow: <SampleNextArrow className={classes.navClassPrev} />,
        prevArrow: <SamplePrevArrow className={classes.navClassNext} />,
        responsive: [
        {
            breakpoint: 1155,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1
            }
        }
    ]
    }

    const handleUserDetails = (userID) => {
        myTeamsStore.cleanUserInfo();
        setDetailedDialogFor(userID);
        myTeamsStore.loadDetailedUserInfo(userID)
    }


    const handleChangePeriod = (e) => {
        myTeamsStore.setPVPeriodID(e.target.value)
    }

    const handleCloseDialogFor = () => {
        setDetailedDialogFor(null)
    }

    const handleSwitchUser = () => {
        myTeamsStore.setUser(detailedDialogFor)
        setDetailedDialogFor(null)
    }
    const handleChangeTeam = (e) => {
        myTeamsStore.setCurrentTeam(e.target.value)
    }

    const handleViewTypeChange = (e) => {
        myTeamsStore.setCurrentTeam(e.target.value)
    }


    const handleToggleWatch = async () => {
        const remove = detailedUserInfo.watches?.length > 0;

        if (remove)
            await myTeamsStore.removeWatch(detailedUserInfo.UserID)
        else
            await myTeamsStore.addWatch(detailedUserInfo.UserID)

        setDetailedDialogFor(null)
        myTeamsStore.refresh()
    }
    const handleToggleJump = async () => {
        const remove = detailedUserInfo.jumps?.length > 0;
        if (remove)
            await myTeamsStore.removeJump(detailedUserInfo.UserID)
        else
            await myTeamsStore.addJump(detailedUserInfo.UserID)

        setDetailedDialogFor(null)
        //myTeamsStore.refresh()

    }

    const handleRemoveJump = async (e, userID) => {
        e.preventDefault()
        await myTeamsStore.removeJump(userID)
        myTeamsStore.loadJumpList()
    }

    const showLegend = async () => {
        setShowingLegend(true)
    }

    const hideLegend = async () => {
        setShowingLegend(false)
    }

    const showMonthEndDialog = async () => {
        setShowingMonthEnd(true)
    }

    const hideMonthEndDialog = async () => {
        setShowingMonthEnd(false)
    }

    if (myTeamsLoading) return <CircularProgress size={50}/>;

    return <>
        {dashboardUserInfo && <>
            <Accordion defaultExpanded>
                <AccordionSummary color="primary" className={classes.accordionSummary}
                                  expandIcon={<ExpandMoreIcon/>}>
                    <a onClick={(e) => {
                        e.preventDefault();
                        handleUserDetails(dashboardUserInfo.UserID)
                    }}
                       className={clsx(classes.smp, GetPersonClass(dashboardUserInfo, "Bi"))}>
                    </a>
                    <Typography variant="h6" className={classes.heading}>
                        {dashboardUserInfo.FullName + " - " + dashboardUserInfo.RankAbbr}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ThemeProvider theme={mdResize}>
                        <Grid container>
                            <Grid item md={6} sm={12} container direction="column">
                                <Select variant={"outlined"} value={PVPeriodID} onChange={handleChangePeriod}>
                                    {periodsStore.periods.map(period => {
                                        return <MenuItem key={period.PVPeriodID}
                                                         value={period.PVPeriodID}>{moment.utc(period.StartDate).format("MMMM yyyy")}</MenuItem>
                                    })}
                                </Select>
                                <UserPanel PVPeriodID={PVPeriodID} t={t} user={dashboardUserInfo}></UserPanel>
                            </Grid>
                            <Grid item md={6} sm={12} container direction="column" alignItems={"center"}>
                                {showMonthEnd && <Button color={"primary"} variant={"contained"}
                                                         onClick={showMonthEndDialog}>
                                    {t("monthEnd", {month: moment().add(new Date().getDate() < 14 ? -1 : 0, "month").format("MMMM")})}
                                </Button>}
                                <CustomerRequirements
                                    value={dashboardUserInfo.requirements?.RegisteredCustomerPV}
                                    requiredValue={dashboardUserInfo.requirements?.PVRequirement}
                                    showDetails={dashboardUserInfo.requirements?.isForCurrentUser}
                                    requirements={dashboardUserInfo.requirements}

                                />
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary color="primary" className={classes.accordionSummary}
                                  expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="h6" className={classes.heading}>
                        {t("MyTeamsDashboard")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{display:"block"}}>
                        <Slider {...options} className={classes.slider}>
                            {dashboardUserInfo.team && dashboardUserInfo.team.map((member, index) => {
                                return <Box className={clsx(classes.panelWrapper)}>
                                            <Box onClick={(e) => { handleChangeTeam({target: {value: index + 1}})}}
                                                        key={member.UserID}
                                                        className={clsx(classes.panel, {[classes.highlight]: showFullLOSModule && index + 1 === currentTeam})}>
                                                <Box className={classes.teamMemberTitle}>{member.LastName}</Box>
                                                <Box className={classes.teamMemberRank}>{member.RankName}</Box>
                                                <Box display="flex" flexDirection="column" paddingLeft={1} paddingRight={1}>
                                                    <UserPanel PVPeriodID={PVPeriodID} t={t} user={member}></UserPanel>
                                                </Box>
                                            </Box>
                                        </Box>
                            })}
                        </Slider>
                </AccordionDetails>
            </Accordion>
        </>
        }
        {dashboardUserInfo && <AppBar position={"sticky"} padding={2} classes={{root: classes.appBar}}>
            <Grid container className={classes.toolbar}>
                <Grid item xs={12} container justifyContent="space-between" alignItems={"center"}>
                    <Box>
                        {t("team")} <Select variant={"outlined"} value={currentTeam} onChange={handleChangeTeam}
                                            classes={{select: classes.select}}>
                        {dashboardUserInfo && dashboardUserInfo.team.map((team, index) => {
                            return <MenuItem key={team.UserID} value={index + 1}>{team.LastName}</MenuItem>
                        })}
                    </Select>
                    </Box>

                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Button endIcon={<ExpandMoreIcon/>} onClick={handleSearchClick}>{t("search")}</Button>
                        {fullTrail.length > 0 &&
                            <Button endIcon={<ExpandMoreIcon/>} onClick={handleTrailClick}>{t("fullTrail")}</Button>}
                        {ranks.length > 0 &&
                            <Button endIcon={<ExpandMoreIcon/>} onClick={handleFilterClick}>{t("filter")}</Button>}
                        <Button endIcon={<ExpandMoreIcon/>} onClick={handleJumpClick}>{t("jump")}</Button>
                    </ButtonGroup>

                    <Popover
                        id="search-menu"
                        anchorEl={anchorSearchEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        open={searchOpen}
                        onClose={handleSearchClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * searchPanelSize,
                                width: '30ch',
                            },
                        }}
                    >
                        <UplineSearch show={searchOpen} handleUserSelectFromSearch={handleUserSelectFromSearch}  enableUplineSearch={false}/>
                    </Popover>

                    <Popover
                        id="jump-menu"
                        anchorEl={anchorJumpEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        keepMounted
                        open={jumpOpen}
                        onClose={handleJumpClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 6.5,
                                width: '40ch',
                            },
                        }}
                    >
                        <List>
                            {jumpList.map((jump) => (
                                <MenuItem key={jump.LOSUserID} onClick={() => {
                                    handleUserSelectFromJump(jump.LOSUserID)
                                }}>
                                    {jump.FirstName} {jump.LastName} ({jump.LOSUserID})
                                    <ListItemSecondaryAction>
                                        {(removingJumps.includes(jump.LOSUserID) || removingJumps.includes(-99))
                                            ? <CircularProgress size={20}/>
                                            : <IconButton size={"small"} onClick={e => {
                                                handleRemoveJump(e, jump.LOSUserID)
                                            }}>
                                                <CloseIcon fontSize="small"/>
                                            </IconButton>
                                        }

                                    </ListItemSecondaryAction>
                                </MenuItem>
                            ))}
                        </List>
                        <br/>
                    </Popover>


                    <Popover
                        id="trail-menu"
                        anchorEl={anchorTrailEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        keepMounted
                        open={trailOpen}
                        onClose={handleTrailClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 6.5,
                                width: '40ch',
                            },
                        }}
                    >
                        <Box padding={2}>
                            {fullTrail.map((trailItem) => (
                                <MenuItem key={trailItem.UserID} onClick={() => {
                                    handleUserSelectFromTrail(trailItem.UserID)
                                }}>
                                    {trailItem.FirstName} {trailItem.LastName}
                                </MenuItem>
                            ))}
                        </Box>
                    </Popover>

                    <Popover
                        id="filter-menu"
                        anchorEl={anchorFilterEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        keepMounted
                        open={filterOpen}
                        onClose={handleFilterClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 6.5,
                                width: '40ch',
                            },
                        }}
                    >
                        <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<ExpandMoreIcon fontSize="large"/>}
                            defaultExpandIcon={<ChevronRightIcon/>}
                        >
                            {ranks.map((rankItem) => (
                                <TreeItem nodeId={rankItem.RankID}
                                          label={<ListItem>
                                              <ListItemText primary={rankItem.Name}/>
                                              <ListItemSecondaryAction>
                                                  {!rankItem.radioGroup && <Checkbox
                                                      checked={rankItem.checked}
                                                      onClick={e => {
                                                          toggleFilter(e, rankItem)
                                                      }}
                                                      value={rankItem.RankID}/>}
                                              </ListItemSecondaryAction>
                                          </ListItem>}>
                                    {rankItem.children && rankItem.children.map((rankItemChild) => {
                                        return <TreeItem nodeId={rankItemChild.RankID}
                                                         label={<ListItem>
                                                             <ListItemText primary={rankItemChild.Name}/>
                                                             <ListItemSecondaryAction>
                                                                 <Checkbox
                                                                     checked={rankItemChild.checked}
                                                                     onClick={e => {
                                                                         toggleFilter(e, rankItemChild, rankItem)
                                                                     }}
                                                                     value={rankItemChild.RankID}/>
                                                             </ListItemSecondaryAction>
                                                         </ListItem>}/>
                                    })}
                                </TreeItem>
                            ))}
                        </TreeView>
                    </Popover>

                    {/*<Select variant={"outlined"} value={viewType} onChange={handleViewTypeChange}>*/}
                    {/*        <MenuItem  value={1}>{t("byAvatar")}</MenuItem>*/}
                    {/*        <MenuItem  value={2}>{t("byList")}</MenuItem>*/}
                    {/*</Select>*/}

                    <Fab aria-label="edit" size={"small"} onClick={showLegend}>
                        <HelpIcon/>
                    </Fab>

                </Grid>
            </Grid>
        </AppBar>}

        <div className={classes.losBody}>

            <ul className={classes.ulLOS} id="ulLOS">

                {myTeamsInfo.map((item, index) => {
                    return <li className={clsx(item.Css, classes.listGroupItem)}>
                        <div
                            className={clsx(classes.p, {[classes.hidden]: isPersonHidden(item, myTeamsStore)})}>
                            <a href="#" data-uid={item.UserID}
                               className={clsx(classes.smp, GetPersonClass(item))}
                               onClick={() => {
                                   handleUserDetails(item.UserID)
                               }}>
                                {item.Num}
                                <span className={classes.span}>{item.DisplayName}</span>
                                {item.IsPowerPlayer && <div className='power'></div>}
                            </a>
                            <div className={classes.smc}>
                                {item.children && item.children.map(child => {
                                    return <a href="#" data-uid={child.UserID}
                                              className={GetPersonClass(child)} onClick={() => {
                                        handleUserDetails(child.UserID)
                                    }}>
                                        {GetPersonNumber(Math.floor(child.PV), child.TeamLeaders)}<span
                                        className={classes.span}>{child.DisplayName}</span>
                                        {child.IsPowerPlayer && <div className='power'></div>}</a>
                                })}
                            </div>
                            {item.watches && item.watches.map(watch => {
                                return <div className="losWatchLine">
                                    <span>{watch.Description}</span>
                                </div>
                            })}
                        </div>
                        {item.Bonus && <span className={classes.bracket}>{Math.round(item.Bonus * 100)}</span>}
                    </li>
                })}
            </ul>
        </div>
        <BonusChart showingLegend={showingLegend} hideLegend={hideLegend} showSymbols={true}/>
        <Dialog open={showingMonthEnd} maxWidth={"sm"} fullWidth onClose={hideMonthEndDialog}>
            <DialogContent>
                <Typography
                    dangerouslySetInnerHTML={{__html: t("monthEndDescription", {month: moment().add(new Date().getDate() < 14 ? 0 : 1, "month").format("MMMM")})}}>

                </Typography>


            </DialogContent>

            <DialogActions>
                <Button onClick={hideMonthEndDialog} color="secondary">Close</Button>
            </DialogActions>
        </Dialog>

        {detailedDialogFor && <Dialog maxWidth={"sm"} fullWidth open={detailedDialogFor} onClose={handleCloseDialogFor}>
            <DialogTitle>
                {(!detailedUserLoading && detailedUserInfo) && <>{detailedUserInfo.FullName} - {detailedUserInfo.UserID}</>}
            </DialogTitle>
            <DialogContent>
                {detailedUserLoading || !detailedUserInfo
                    ? <CircularProgress/>
                    : <>
                        <Box display="flex" justifyContent="center" padding={1}>{detailedUserInfo.RankName}</Box>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} container direction="column">
                                <Typography noWrap variant={"body2"}>PV
                                    Total: {formatNumber(detailedUserInfo.PV, 2)}</Typography>
                                <Typography noWrap variant={"body2"}>Personal
                                    PV: {formatNumber(detailedUserInfo.PVPersonal - detailedUserInfo.PVCustomer, 2)}</Typography>
                                <Typography noWrap variant={"body2"}>Customer
                                    PV/$: {formatNumber(detailedUserInfo.PVCustomer, 2)}/${formatNumber(detailedUserInfo.CustomerSales, 2)}</Typography>
                                <Typography noWrap
                                            variant={"body2"}>#Leaders: {detailedUserInfo.TeamLeaders}</Typography>
                                <Typography noWrap variant={"body2"}>Seminar
                                    Tickets: {formatNumberOrZero(detailedUserInfo.totals?.TicketSeminar || 0, 0)} ({detailedUserInfo.TicketSeminar})</Typography>
                                <Typography noWrap variant={"body2"}>Seminar
                                    Attendance: {detailedUserInfo.totals?.SeminarAttendance}</Typography>
                                <Typography noWrap variant={"body2"}>Convention
                                    Tickets: {formatNumberOrZero(detailedUserInfo.totals?.TicketConvention || 0, 0)} ({detailedUserInfo.TicketConvention})</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography noWrap
                                            variant={"body2"}>Enrolled: {moment(detailedUserInfo.EnrollmentDate).format("MM/DD/YYYY")}</Typography>
                                <Typography noWrap
                                            variant={"body2"}>Cust.Q.Mo: {moment(detailedUserInfo.CustomerRequirementMonth).format("M/yy")}</Typography>
                                <Typography noWrap variant={"body2"}>Paid As:
                                    {formatNumber(detailedUserInfo.RankNameLastPaid, 0)}</Typography>
                                <Typography noWrap variant={"body2"}>Career:
                                    {formatNumber(detailedUserInfo.RankNameTop, 0)}</Typography>
                                <Typography noWrap variant={"body2"}>Standing Order Ticket
                                    (SOT): {formatNumberOrZero(detailedUserInfo.totals?.SOT, 0)} ({detailedUserInfo.PkgSOT})</Typography>
                                <Typography noWrap variant={"body2"}>Processed
                                    SOT: {formatNumberOrZero(detailedUserInfo.totals?.SOTProcessed, 0)} ({detailedUserInfo.SOTProcessed})</Typography>
                                <Typography noWrap variant={"body2"}>Marketing System
                                    (MS): {formatNumberOrZero(detailedUserInfo.totals?.Training, 0)}</Typography>
                                <Typography noWrap variant={"body2"}>Processed
                                    MS: {formatNumberOrZero(detailedUserInfo.totals?.TrainingProcessed, 0)}</Typography>
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" padding={1} paddingTop={2}>
                            <UserSubscriptions user={detailedUserInfo}></UserSubscriptions>
                        </Box>
                        {detailedUserInfo.hasContactInfo &&
                            <Paper elevation={1} style={{backgroundColor: "#4e4e4e", padding: 10}}>
                                <Typography>{detailedUserInfo.contactInfo.Address1}</Typography>
                                <Typography>{detailedUserInfo.contactInfo.Address2}</Typography>
                                <Typography>{detailedUserInfo.contactInfo.City}, {detailedUserInfo.contactInfo.Region} {detailedUserInfo.contactInfo.Region} {detailedUserInfo.contactInfo.Country}</Typography>
                                <Typography><Link
                                    href='mailto:{detailedUserInfo.contactInfo.Email}'>{detailedUserInfo.contactInfo.Email}</Link></Typography>
                                <Typography>{detailedUserInfo.contactInfo.Telephone}</Typography>

                            </Paper>}

                    </>}


            </DialogContent>
            <DialogActions style={{justifyContent: detailedUserInfo ? "space-between" : "flex-end"}}>
                {detailedUserInfo && detailedUserInfo.allowView &&
                    <Button color={"primary"} variant={"contained"} onClick={handleSwitchUser}>View</Button>}

                {(detailedUserInfo && detailedUserInfo.allowView && detailedUserInfo.UserID !== currentUser.userID)
                    && <Button color={detailedUserInfo.watches?.length > 0 ? "secondary" : "primary"}
                               onClick={handleToggleWatch}
                               disabled={modifyingWatch}
                               startIcon={
                                   modifyingWatch
                                       ? <CircularProgress size={25}/>
                                       : detailedUserInfo.watches?.length > 0
                                           ? <RemoveIcon/>
                                           : <AddIcon/>
                               }
                               variant={"contained"}>Watch</Button>}

                {(detailedUserInfo && detailedUserInfo.allowView && detailedUserInfo.UserID !== currentUser.userID)
                    && <Button color={detailedUserInfo.jumps?.length > 0 ? "secondary" : "primary"}
                               onClick={handleToggleJump}
                               disabled={modifyingJump}
                               startIcon={
                                   (modifyingJump || removingJumps.includes(detailedUserInfo.UserID))
                                       ? <CircularProgress size={25}/>
                                       : detailedUserInfo.jumps?.length > 0
                                           ? <RemoveIcon/>
                                           : <AddIcon/>}
                               variant={"contained"}>Jump</Button>}

                <Button color={"secondary"} variant={"contained"} onClick={handleCloseDialogFor}>Close</Button>
            </DialogActions>
        </Dialog>}


    </>

})
import React, {Component, useEffect, useState} from 'react';
import MediaListItem from './MediaListItem';
import {observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";

function MediaList ({shoppingMatch, productMedia, showType}) {

  const [currentIdx, setCurrentIdx] = useState(null)
  const [currentSongURL, setCurrentSongURL] = useState(null)

  const pauseTrack = (idx) => {
    if (currentSongURL) {
      setCurrentIdx( idx )
      currentSongURL.pause();
      setCurrentSongURL(null)
    }
  }

  useEffect(()=>{
    return ()=>{
      pauseTrack()
    }
  },[])

  const playTrackAudio = (audioURL, idx) => {
    setCurrentIdx( idx )

    let audio = new Audio(audioURL)

    if (!currentSongURL) {
      setCurrentSongURL(audio)
      audio.play();
      return
    }

    if (currentSongURL.src !== audioURL) {
      currentSongURL.pause();

      setCurrentSongURL(audio)
      audio.play();
      return;
    }

    audio.play();
  }

  const renderMediaListItems = () => {
    if (productMedia) {
      return productMedia.map((item, index) => {
        return <MediaListItem
            shoppingMatch={shoppingMatch}
            showType={showType}
          currentIdx={currentIdx}
          idx={index}
          key={index}
          pauseTrack={(index) => { pauseTrack(index) }}
          playTrackAudio={(songURL, index) => { playTrackAudio(songURL, index) }}
          audioItem={item} />
      })
    }
  }

    return (  <Grid container spacing={2} direction={"column"}>
            {renderMediaListItems()}
          </Grid>
    )

}

export default observer(MediaList);

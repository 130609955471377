import React, { useEffect, useMemo, useState } from "react";
import {observer, useLocalObservable} from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { joinPath } from "../../utils/helpers";
import ProductCategory from "../../components/shop/ProductCategory/ProductCategory";
import { useStores } from "../../hooks/use-stores";
import ProductDetail from "./ProductDetail/ProductDetail";
import Cart from "./Cart/Cart";
import AutoShipStatusBanner from "../../components/shop/AutoshipStatusBanner";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import AutoShipBanner from "../../components/shop/AutoShipBanner";
import ReactMarkdown from 'react-markdown';
import {ShopContext, shopTypes } from "./shopContext"
import BreadCrumb from "../../components/shop/Header/BreadCrumb/BreadCrumb";
import uniqBy from "lodash/uniqBy";

const useStyles = makeStyles((theme) => ({
    newAutoshipText: {
        whiteSpace: "pre-line"
    }

}));

export default observer(function ShopIndex({standalone}) {
    const MyContext = React.createContext("autoship");
    const autoShipMatch = useRouteMatch();
    const { authStore, shopStore ,commonStore } = useStores();
    const { currentUser } = authStore;
    const { autoshipIsCancelled, autoshipIsExisting, autoshipIsNew,
        productDetailLoading, productDetail, productsByCategory} = shopStore;
    const { language } = commonStore;
    const { t } = useTranslation("shopping");
    const classes = useStyles();
    const shopParams= shopTypes.autoship
    shopParams.home = autoShipMatch.url;
    const isHome = useRouteMatch( {path: autoShipMatch.url, exact: true } );
    const categoryMatch = useRouteMatch( {path: joinPath( autoShipMatch.url, "category/:categoryId" )} );

    useEffect(() => {
        if (currentUser)
            authStore.loadShopUserDetails();
        shopStore.loadCurrentAutoShipInfo();
    }, [currentUser]);


    const toggleAutoshipModal = function () {
        shopStore.toggleAutoshipModal();
    };


    useEffect(() => {
        shopStore.loadAutoShipCartItems();
    }, []);






    return <ShopContext.Provider value={shopParams}>

        <Switch>
        <Route path={autoShipMatch.url} exact
            render={() =>
                <>
                    <Container maxWidth={"md"}>
                        <BreadCrumb
                            shoppingMatch={autoShipMatch}
                            homeItems={ [{label: "Marketplace Products", url: "/royalstarfish/royal/marketplaceproducts/"}, {label: "Autoship", url: "/royalstarfish/royal/marketplaceproducts/autoship" }] }
                            homeUrl={!standalone &&  "/royalstarfish/royal/marketplaceproducts/autoship" }
                        />
                        {autoshipIsCancelled &&
                            <Alert color={"error"} variant={"filled"}>
                                {t("autoship_messages.welcome_back")}<br /><br />
                                {t("autoship_messages.cancelled")}
                            </Alert>
                        }

                        {autoshipIsExisting &&
                            <Alert color={"success"} variant={"filled"}>
                                {t("autoship_messages.welcome_back")}<br /><br />
                                {t("autoship_messages.existing")}
                            </Alert>
                        }

                        {autoshipIsNew &&
                            <Alert color={"info"} variant={"filled"}>
                                {t("autoship_messages.welcome_new")}<br /><br />
                                <ReactMarkdown>
                                    {t('shopping:autoship_messages.new')}
                                </ReactMarkdown>
                            </Alert>
                        }
                    </Container>
                    <ProductCategory shoppingMatch={autoShipMatch} autoship={true} shoppingRoot={autoShipMatch.path} />
                </>
            } />

        <Route path={joinPath(autoShipMatch.url, `category/:categoryId/product-detail/:productSKU`)}
            render={(p) =>
                <Container>
                    <BreadCrumb
                    shoppingMatch={autoShipMatch}
                    homeItems={ [{label: "Marketplace Products", url: "/royalstarfish/royal/marketplaceproducts/"}, {label: "Autoship", url: "/royalstarfish/royal/marketplaceproducts/autoship" }] }
                    homeUrl={!standalone &&  "/royalstarfish/royal/marketplaceproducts/autoship" }
                    items={
                        (!productDetailLoading && productDetail)
                            ? [ { label: t(`shopping:subcategories.${productDetail.productCategoryID }`)
                                , url: "/royalstarfish/royal/marketplaceproducts/autoship/category/" + productDetail.productCategoryID  },
                                { label: productDetail.productName
                                    , url: "/royalstarfish/royal/marketplaceproducts/autoship/category/" + productDetail.productCategoryID  + '/product-detail/' + productDetail.productSKU }

                            ]
                            : []
                    }
                />
                <ProductDetail shoppingMatch={autoShipMatch} autoship={true}
                                               categoryId={p.match.params.categoryId}
                                               productSKU={p.match.params.productSKU}
                                               params /></Container>} />

        <Route path={joinPath(autoShipMatch.url, `category/:categoryId`)}
            render={(p) =>
                <Container>
                    <BreadCrumb
                        shoppingMatch={autoShipMatch}
                        homeItems={ [{label: "Marketplace Products", url: "/royalstarfish/royal/marketplaceproducts/"}, {label: "Autoship", url: "/royalstarfish/royal/marketplaceproducts/autoship" }] }
                        homeUrl={!standalone &&  "/royalstarfish/royal/marketplaceproducts/autoship" }
                        items={
                            (p.match.params.categoryId)
                                ? [ { label: t(`shopping:subcategories.${p.match.params.categoryId }`)
                                    , url: "/royalstarfish/royal/marketplaceproducts/autoship/category/" + p.match.params.categoryId },
                                ]
                                : []
                        }
                        />
                    <ProductCategory shoppingMatch={autoShipMatch} autoship={true}
                                            categoryId={p.match.params.categoryId}
            /></Container>}/>

        <Route path={joinPath(autoShipMatch.url, `cart`)}
            render={(p) =>
                <Container>
                    <BreadCrumb
                        shoppingMatch={autoShipMatch}
                        homeItems={ [{label: "Marketplace Products", url: "/royalstarfish/royal/marketplaceproducts/"}, {label: "Autoship", url: "/royalstarfish/royal/marketplaceproducts/autoship" }] }
                        homeUrl={!standalone &&  "/royalstarfish/royal/marketplaceproducts/autoship" }
                    />
                <Cart shoppingMatch={autoShipMatch} autoship={true} />
                </Container>
                } />
    </Switch>
    </ShopContext.Provider>;
});
import IdLessRestService from "./IdLessRestService";


export default class SendoutService extends IdLessRestService {
    constructor() {
        super('/api/sendouts');
    }


    search(options) {
        return this.get(`${this.baseUrl}/search?term=${options.term}&productLang=${options.productLang}`);
    }

    email(recipientEmail, link) {
        return this.post(`${this.baseUrl}/email`, { recipientEmail, link });
    }

}

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { physicalAndDigitalSKUs } from '../../../utils/physicalAndDigitalSKUs';
import MediaList from '../MediaList/MediaList';
import Speaker from '../MediaList/Speaker';
import { getCookie, deleteCookie } from '../../../utils/cookieUtil';
import ErrorMessageModal from '../../../components/shop/ErrorMessageModal';
import SuccessMessageModal from '../../../components/shop/SuccessMessageModal';
import { useStores } from "../../../hooks/use-stores";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import ShareProductModal from "./controls/ShareProductModal";
import ProductDetails from "./controls/ProductDetails";
import ProductDescription from "./controls/ProductDescription";
import SpeakersCarousel from "./controls/SpeakersCarousel";
import AdditionalProductsCarousel from "./controls/AdditionalProductsCarousel";
import AddedToCartModal from "./controls/AddedToCartModal";
import PreviewShareProductModal from "./controls/PreviewShareProductModal";
import VideoPreviewModal from "./controls/VideoPreviewModal";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { joinPath } from "../../../utils/helpers";
import {useShopType} from "../useShopType";
import CreateShopAccount from "../CreateShopAccount";


const useStyles = makeStyles((theme) => ({
  productDetailsContent: {
    paddingTop: 70,
    paddingBottom: 20
  }
}));


function ProductDetailDescription({ shoppingMatch, shoppingRoot, product, isQuickBuy, parentCategory, onAdditionalProductHandler }) {
  if ( !shoppingRoot )
    shoppingRoot= shoppingMatch
  const shopOptions = useShopType()
  const [width, setWidth] = useState();
  const history = useHistory();
  const classes = useStyles();
  const [height, setHeight] = useState();
  const [physicalAndDigitalDropdownEnabled, setPhysicalAndDigitalDropdownEnabled] = useState(false);
  const [physicalAndDigitalDropdownValue, setPhysicalAndDigitalDropdownValue] = useState(null);
  const [showProceedToCheckout, setShowProceedToCheckout] = useState(false);
  const [showShareProductModal, setShowShareProductModal] = useState(false);
  const [previewShareProductEmail, setPreviewShareProductEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [shareProductErrorMessage, setShareProductErrorMessage] = useState(null);
  const [mediaURL, setMediaURL] = useState(null);
  const [selectedQty, setSelectedQty] = useState(1);
  const [shareProductRecipientName, setShareProductRecipientName] = useState('');
  const [shareProductRecipientEmail, setShareProductRecipientEmail] = useState('');
  const [shareProductMessage, setShareProductMessage] = useState('');
  const [speakerName, setSpeakerName] = useState('');
  const [createAccountModalShow, setCreateAccountModalShow] = useState(false);
  //const [speakerAudio, setSpeakerAudio] = useState([])


  const { authStore, shopStore, commonStore } = useStores();
  const { language } = commonStore;
  const { currentUser } = authStore;
  const { userCartGuid, autoShipUserCartGuid, subscriptionUserCartGuid, sendProductEmailError, speakerAudio, speakerAudioLoading,
    addingItemToCart, customerEnrollWithProductLinkLoading, currency, userCustomerLevelID, customerLevelID, market: marketID } = shopStore;
  const { t } = useTranslation();

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };


  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (product) {
      if (physicalAndDigitalSKUs[product.productSKU]) {
        if (product.productSKU.charAt(0) === 'e') {
          setPhysicalAndDigitalDropdownEnabled(true);
          setPhysicalAndDigitalDropdownValue('Digital');
        } else {
          setPhysicalAndDigitalDropdownEnabled(true);
          setPhysicalAndDigitalDropdownValue('Physical');
        }
      }
    }
  }
    , [product]);





  const toggleShowProceedToCheckout = () => {
    setShowProceedToCheckout(!showProceedToCheckout);
  };

  const proceedToShopHome = () => {
    setShowProceedToCheckout(!showProceedToCheckout);
    history.push(shoppingMatch.url);
  };

  const shareButtonClickHandler = async () => {
    const is_desktop = getCookie('is_desktop');
    const nativeapp = getCookie('nativeapp');
    if (is_desktop || (nativeapp == null || !nativeapp)) {
      toggleShowShareProductModal();
      return;
    }


    let { url } = await shopStore.getCustomerEnrollWithProductLink({ productID: product.productID, lang: language });
    window.location.href = `sms://${url}`;
  };

  const toggleShowShareProductModal = () => {
    setShowShareProductModal(!showShareProductModal);
  };

  const togglePreviewShareProductEmail = () => {
    toggleShowShareProductModal();
    setPreviewShareProductEmail(!previewShareProductEmail);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  const clearShareProductErrorMessage = () => {
    setShareProductErrorMessage(null);
  };

  const clearSuccessMessage = () => {
    setSuccessMessage(null);
    resetShareProductModalData();
  };

  const onSpeakerModalClick = async (speaker) => {
    await shopStore.getSpeakerAudio(speaker, { speaker, language, customerLevelID, marketID });
    setSpeakerName(speaker);
  };

  const closeSpeakersModal = () => {
    shopStore.setSpeakerAudio(null);
    setSpeakerName(null);
  };

  const onModalImageClick = (media) => {
    setMediaURL(media);
  };

  const onModalClose = () => {
    try {
      setMediaURL(null);
      const iframe = document.getElementById('iframeId');
      const videoSrc = iframe.src;
      iframe.src = "";
      iframe.src = videoSrc;

    }
    catch(e){
    }
  };

  const isProductDigital = useMemo(() => {
    if (!product) return null;
    if (product.productSKU.charAt(0) === 'e' && product.productSKU.substring(0, 2) !== 'ed') {
      return 'Digital';
    }
    return 'Physical';
  }, [product]);

  const onQuantityChange = (e) => {
    setSelectedQty(e.target.value);
  };

  const onRecipientNameChange = (e) => {
    setShareProductRecipientName(e.target.value);
  };

  const onRecipientEmailChange = (e) => {
    setShareProductRecipientEmail(e.target.value);
  };

  const onShareProductMessageChange = (e) => {
    setShareProductMessage(e.target.value);
  };



  const resetShareProductModalData = () => {
    setShareProductRecipientName('');
    setShareProductRecipientEmail('');
    setShareProductMessage('');
  };



  const addItem = async (productID) => {
    switch (shopOptions.shopId) {
      case "autoship":
        await shopStore.addItemToAutoShipCart({ productID, cartID: autoShipUserCartGuid, lang: language, qty: selectedQty });
        await shopStore.loadAutoShipCartItems();
        break;
      case "subscriptions":
        await shopStore.addItemToSubscriptionCart({ productID, cartID: subscriptionUserCartGuid, lang: language, qty: selectedQty });
        await shopStore.loadSubscriptionsCartItems();
        break;
      default:
        await shopStore.addItemToCart({ productID, cartID: userCartGuid, lang: language, qty: selectedQty });
        await shopStore.getUserCart({ cartGuid: userCartGuid, customerLevelID: userCustomerLevelID || customerLevelID });
        break;
    }

    if (isQuickBuy) {
      onCheckoutClick();
    }
    else {
      setShowProceedToCheckout(true);
    }
  };

  const onAddToCart = () => {
    const sku = physicalAndDigitalSKUs[product.productSKU];
    if (physicalAndDigitalDropdownValue === 'Both' && sku) {
      addItem(sku.PhysicalProductId);
      addItem(sku.DigitalProductId);
    } else if (physicalAndDigitalDropdownValue === 'Physical' && sku) {
      addItem(sku.PhysicalProductId);
    } else if (physicalAndDigitalDropdownValue === 'Digital' && sku) {
      addItem(sku.DigitalProductId);
    } else {
      addItem(product.productID);
    }
  };

  const onCheckoutClick = () => {
    if ( authStore.isGuest ){
      setCreateAccountModalShow(true);
    }
    else
      history.push(joinPath(shoppingRoot.url, '/cart'));
  };

  const shareProductInEmail = async () => {

    if (shareProductRecipientName === '' || shareProductRecipientEmail === '') {
      return;
    }

    const data = {
      productID: product.productID,
      recipientEmail: shareProductRecipientEmail,
      recipientName: shareProductRecipientName,
      message: shareProductMessage,
      language
    };

    let result = await shopStore.sendProductEmail(data);


    if (result) {
      toggleShowShareProductModal();
      setSuccessMessage('Your email was sent to: ' + shareProductRecipientEmail);
    }
  };


  const onPhysicalAndDigitalDropdownChange = (e) => {
    if (e && e.value) {
      setPhysicalAndDigitalDropdownValue(e.value);
    }
  };
  if (!product) return ('');

  const {
    productName,
    productPrice,
    productPV,
    productType,
    productTC,
    productDescription,
    productSummary,
    productMedia,
    additionalProducts,
    bannerImage,
    productDetails,
    productSKU,
    speakers
  } = (product || {});

  // Owl carousel options
  const options = {
    items: 4,
    nav: false,
    rewind: true,
    autoplay: false,
    margin: 15,
    dots: false,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <ProductDetails
        t={t}
        productName={productName}
        bannerImage={bannerImage}
        productPrice={productPrice}
        currency={currency}
        productTC={productTC}
        productSKU={productSKU}
        productType={productType}
        productPV={productPV}
        productSummary={productSummary}
        productMedia={productMedia}
        isGuest={authStore.isGuest}
        isMember={currentUser && currentUser.isMember}
        isGeneratingCustomerEnrollWithProductLink={customerEnrollWithProductLinkLoading}
        {...{
          selectedQty, onQuantityChange, addingItemToCart
          , onAddToCart, onModalImageClick, physicalAndDigitalDropdownValue
          , width, height, onPhysicalAndDigitalDropdownChange, physicalAndDigitalDropdownEnabled
          , isProductDigital, shareButtonClickHandler
          , productName, bannerImage, productPrice, productTC, productSKU, productType, productPV, productSummary, productMedia
        }}
        options={options}
      />

      <ProductDescription t={t} productDescription={productDescription} width={width} />

      {
        (productDetails && productDetails.length >= 1) ? (
          <>
            <Typography variant={"h5"} className={classes.productDetailsContent}>Product Content</Typography>
            {productDetails.length >= 1 ? (
              <MediaList productMedia={productDetails} shoppingMatch={shoppingMatch} />
            ) : ('')}
          </>
        ) : ('')
      }

      <SpeakersCarousel speakers={speakers}
        speakerAudioLoading={speakerAudioLoading}
        onClickHandler={onSpeakerModalClick} options={options} />

      <AdditionalProductsCarousel t={t} parentCategory={parentCategory}
        additionalProducts={additionalProducts}
        onAdditionalProductHandler={onAdditionalProductHandler}
        options={options} />
      {speakerName && <Speaker
        shoppingMatch={shoppingMatch}
        onAdditionalProductHandler={onAdditionalProductHandler}
        speakerName={speakerName}
        speakerAudio={speakerAudio}
        onClose={closeSpeakersModal}

      />}


      <AddedToCartModal
        t={t}
        product={product}
        selectedQty={selectedQty}
        showProceedToCheckout={showProceedToCheckout}
        productType={isProductDigital}
        onProceedToShopHomeHandler={proceedToShopHome}
        onAddToCartClickHandler={toggleShowProceedToCheckout}
        onProceedToCheckoutClickHandler={onCheckoutClick}
      />
      {createAccountModalShow && <CreateShopAccount
          shopOptions={shopOptions}
          open={createAccountModalShow}
          onClose={() => setCreateAccountModalShow(false)} />}


      {showShareProductModal && <ShareProductModal t={t} product={product}  {...{
        showShareProductModal,
        shareProductErrorMessage,
        toggleShowShareProductModal,
        sendProductEmailError,
        clearShareProductErrorMessage,
        shareProductInEmail
      }} />}

      <VideoPreviewModal t={t} width={width} mediaURL={mediaURL} onClickHandler={onModalClose} />
      {errorMessage && <ErrorMessageModal t={t} message={errorMessage} onClickHandler={clearErrorMessage} />}
      {successMessage && <SuccessMessageModal t={t} message={successMessage} onClickHandler={clearSuccessMessage} />}
    </>
  );

}
export default observer(ProductDetailDescription);

import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import {useStores} from "../../hooks/use-stores";
import ProductDetail from "./ProductDetail/ProductDetail";
import Cart from "./Cart/Cart";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ProductList from "./ProductList/ProductList";
import SubscriptionCategoryRedirect from "./SubscriptionCategoryRedirect";
import IFrame from "../../components/IFrame";
import {fixDomain} from "../../utils/menuUtils";

export default observer(function ShopIndex() {

    const subscriptionsMatch = useRouteMatch();
    const {authStore, shopStore} = useStores();
    const {currentUser} = authStore;
    const {subscriptionInfo} = shopStore;

    useEffect(() => {
        if (currentUser)
            authStore.loadShopUserDetails();
        shopStore.loadSubscriptionInfo();
    }, [currentUser]);


    useEffect(() => {
        shopStore.loadSubscriptionsCartItems();
    }, []);


    return <Container maxWidth={"md"}>
        <Switch>
            <Route path={subscriptionsMatch.url} exact
                   render={() =>
                       <>
                           <Grid container
                                 md={12}
                                 xs={12}>
                               <ProductList
                                   sizeMD={3}
                                   parentRouteMatch={subscriptionsMatch}
                                   category={'category-view-img'}
                                   products={subscriptionInfo}
                               />
                           </Grid>
                       </>
                   }/>

            <Route path={joinPath(subscriptionsMatch.url, `product-detail/:productSKU`)}
                   render={() => <ProductDetail shoppingMatch={subscriptionsMatch}/>}/>

            <Route path={joinPath(subscriptionsMatch.url, `cart`)}
                   render={() => <Cart shoppingMatch={subscriptionsMatch}/>}/>

            <Route path={joinPath(subscriptionsMatch.url, `redirect/:categoryID`)}
                   render={() => <SubscriptionCategoryRedirect shoppingMatch={subscriptionsMatch}/>}/>

            <Route path={joinPath(subscriptionsMatch.url, `manage`)}
                   render={() => <IFrame url={ fixDomain( "https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1622" ) }/>} />

        </Switch>
    </Container>;
});
import {Box, Divider, Link, Typography} from "@material-ui/core";
import React from "react";
import {formatNumber} from "../../../utils/helpers";
import Variance from "./Variance";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useStores} from "../../../hooks/use-stores";
import PanelRow from "./PanelRow";


const useStyles = makeStyles((theme) => ({
    valuesTable: {
        display: 'grid',
        gridTemplateColumns: "2fr 1fr 1fr",
        marginTop:5,
        marginBottom:5,
    }

}));

function PanelProcessedDeclined({data, prefix, moreRows, labels={
    processed: "processed",
    pending: "pending",
    declined: "declined",
    user_canceled: "user_canceled",
    decline_canceled: "decline_canceled",
}}){
    const {t} = useTranslation("dashboard");
    const {dashboardStore}= useStores();
    const classes = useStyles();

    const handleProcessedClick =(type)=> () => {
        if ( prefix )
            dashboardStore.setOpenUsersDialog(prefix + type);
    }

    return <>
        <Box display="flex" justifyContent="center" alignItems="center" padding={"5px"}>
            <Typography
                variant={"h6"}> {formatNumber(data.total)}</Typography>
            <Variance amount={data.totalVar} reverseColor={false}/>
        </Box>
        <Divider/>
        <Box className={classes.valuesTable}>
            <PanelRow handleProcessedClick={handleProcessedClick("Processed")}
                        value={data.processed}
                        varValue={data.processedVar}
                        label={t(labels.processed)}
                        reverseColor={false}
            />
            <PanelRow handleProcessedClick={handleProcessedClick("Pending")}
                        value={data.pending}
                        label={t(labels.pending)}
            />
            <PanelRow handleProcessedClick={handleProcessedClick("Declined")}
                      value={data.declined}
                      varValue={data.declinedVar}
                      label={t(labels.declined)}
                      reverseColor={true}
            />
            <PanelRow handleProcessedClick={handleProcessedClick("Canceled")}
                      value={data.canceled}
                      varValue={data.canceledVar}
                      label={t(labels.user_canceled)}
                      reverseColor={true}
            />
            <PanelRow handleProcessedClick={handleProcessedClick("Removed")}
                      value={data.removed}
                      varValue={data.removedVar}
                      label={t(labels.decline_canceled)}
                      reverseColor={true}
            />
            {moreRows && <>
                {moreRows}
            </>}

        </Box>
    </>

}

export default PanelProcessedDeclined
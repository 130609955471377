import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateDeleteEntity, generateLoadList, generateCreteEntity} from "../utils/mobx";
import keyBy from "lodash/keyBy";

class LibraryStore {
    constructor() {
        makeAutoObservable(this)
    }

    library = []
    audiosPlayed = []
    audiosFavorites = []
    audiosFavoritesRemoving = []
    detailsLoadingFor = []
    libraryLoading = false
    audiosPlayedLoading = false
    audiosFavoritesLoading = false
    audiosFavoritesCreating = false


    path = ""
    searchString = ""
    error = null

    setError(error, type = "library") {
        error = extractErrorMessage(error)
        this.error = error
     }

    setSearchString(value) {
        this.searchString = value
     }
    addDetailsLoadingFor(value) {
        this.detailsLoadingFor.push( value )
     }
    removeDetailsLoadingFor(value) {
        this.detailsLoadingFor.remove( value )
     }


    loadLibrary = ()=>{
        return Promise.all( [ this.loadBaseLibrary(),
        this.loadAudiosPlayed(),
        this.loadAudiosFavorites(),
        ])
    }


    loadBaseLibrary     = generateLoadList( "library", this, "libraryLoading"
        , ()=>{ return services.Library.list() }, "library", )


    loadAudiosPlayed = generateLoadList( "audiosPlayed", this, "audiosPlayedLoading"
        , ()=>{ return services.Library.audiosPlayed() }, "audiosPlayed", )

    loadAudiosFavorites = generateLoadList( "audiosFavorites", this, "audiosFavoritesLoading"
        , ()=>{ return services.Library.favoritesService().list() }, "audiosFavorites", )


    removeFavorite = generateDeleteEntity( "removeFavorites", this, "audiosFavoritesRemoving"
        , async (mediaID)=>{
                const result = await  services.Library.favoritesService().delete(mediaID)
            const existing  = this.audiosFavorites.find( (item)=>item.mediaID === mediaID )
            if ( existing)
                this.audiosFavorites.remove(existing)
            return result
        }, "audiosFavorites", )

    addFavorite = generateCreteEntity( "addFavorites", this, "audiosFavoritesCreating"
        , async (mediaID, media)=>{
            await services.Library.favoritesService().create({mediaID})
            const existing  = this.audiosFavorites.find( (item)=>item.mediaID === mediaID )
            if ( !existing )
                this.audiosFavorites.unshift(media)
    })

    get playedIndex (){
        return keyBy( this.audiosPlayed, "mediaID" )
    }

    get series (){
        return this.library.length>0 ? this.library[0].series:[]
    }

    get speakers (){
        return this.library.length>1 ? this.library[1].speakers:[]
    }

    get recentlyPurchased (){
        return this.library.length>3 ? this.library[3].recentlyPurchased:[]
    }

    get recentlyPlayed (){
        return this.library.length>3 ? this.library[2].recentlyPlayed:[]
    }

}

export default LibraryStore
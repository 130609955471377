import React, {useMemo, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/use-stores";
import Select from "@material-ui/core/Select";
import {CircularProgress, MenuItem} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useShopType} from "../useShopType";

function formatNumber(number, prefix="") {
    let n = Number(number);
    if (isNaN(n)) return (prefix &&  number.startsWith(prefix))?number:`${prefix}${number}`;
    return prefix + n.toFixed(2);

}

const useStyles = makeStyles((theme) => ({

    cartCell: {
        padding: "10px 20px",
        "&:not(:first-child)": {
            borderTop: "1px solid #666666"
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 5px",
        }
    },

    cartCellContent: props => ({
        display: "grid",
        gridTemplateColumns: props.isMember ? "1fr 8fr 5fr 3fr" : "1fr 7fr 1fr",
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: props.isMember ? "6fr 2fr 2fr" : "7fr 1fr",
            fontWeight: "normal",
            fontSize: "initial"
        },
    }),
    cartImg: {
        width: 100,
        height: 100,
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },

    cartProductArea: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: "small",
            paddingLeft: 5,
            paddingRight: 5,
        },
        display: "flex",
        justifyContent: "space-between"
    },

    cartDetailText: {
        alignSelf: "center",
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: "small",
            paddingRight: 0,
            fontWeight: "normal",
        },
        textAlign: "center",
        fontWeight: "bold"
    },
    cartDropdown: {
        lineHeight: "10px"
    },
    cartUpdateAndDelete: {
        display: "inline-grid"
    },
    cartDeleteAndQty: {
        display: "flex",
        float: "left",
        paddingTop: 10,
        alignItems: "center"
    },

    cartProductTitle: {
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: "small",
        },
    },
    cartProductDetails: {
        display: "flex",
    },
    cartProductDetail: {
        display: "flex",
        float: "right",
    },
    cartProductHeader: {
        fontWeight: "bold",
        fontSize: "medium",
        alignSelf: "center",
        paddingRight: 5
    },
    cartSmallText: {
        fontSize: "small",
        alignSelf: "center",
    }
}));


function CartRow({ isMember,
    imageURL,
    productName,
    isDigital,
    productSKU,
    price,
    pv,
    qty,
    productID, onUpdate, onDelete, item

}) {
    const shopOptions = useShopType()
    const classes = useStyles({ isMember });
    const [newQty, setNewQty] = useState(null);
    const { commonStore, shopStore, authStore } = useStores();
    const { language } = commonStore;
    const { autoShipUserCartGuid, userCartGuid, subscriptionUserCartGuid
        , deletingCartItems, deletingAutoShipCartItems
        , deletingSubscriptionCartItems
        , updatingAutoShipCartItems, updatingCartItems, updatingSubscriptionCartItems

    } = shopStore;

    const { shopId } =  shopOptions;
    const isItemsDeleting = useMemo( ()=>{
        switch( shopId){
            case "subscriptions":
                return deletingSubscriptionCartItems.includes(productID);
            case "autoship":
                return deletingAutoShipCartItems.includes(productID);
            case "standard":
                return deletingCartItems.includes(productID);
        }

    }, [productID, shopId])





    const onQtyNumberInputChange = () => {
        const numberInput = document.getElementById('numberInput');
        if (numberInput && numberInput.value && numberInput.value !== qty) {
            setNewQty(numberInput.value);
        } else {
            setNewQty(null);
        }
    };

    const onQtyDropdownChange = (e) => {
        if (e && e.target.value) {
            if (qty === e.target.value) {
                setNewQty(null);
            } else {
                if (e.target.value === '10+') {
                    setNewQty('11');
                } else {
                    setNewQty(e.target.value);
                }
            }
        }
    };

    const QuantityInput = ({ qty = 1, isDigital }) => {
        if (qty > 10) {
            return (
                <input id="numberInput" type="number" className="quantity form-control input-lg pull-right cart-number-input"
                    onChange={onQtyNumberInputChange} defaultValue={qty} />
            );
        } else {
            let options;
            if (isDigital) {
                options = ['0', '1'];
            } else {
                options = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
            }

            return (
                <Select
                    onChange={onQtyDropdownChange}
                    value={qty.toString()} >
                    {options.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                </Select>
            );
        }
    };


    const deleteItem = async (id) => {
        switch( shopOptions.shopId){
            case "subscriptions":
                await shopStore.deleteSubscriptionCartItem({ productID: id, cartID: subscriptionUserCartGuid });
                break;
            case "autoship":
                await shopStore.deleteAutoShipCartItem({ productID: id, cartID: autoShipUserCartGuid });
                break;
            case "standard":
                await shopStore.deleteCartItem({ productID: id, cartID: userCartGuid, lang: language });
                break;
        }
        onDelete();
    };

    const updatingItems = useMemo( ()=>{
        switch (shopId) {
            case "subscriptions":
                return updatingSubscriptionCartItems;
            case "autoship":
                return updatingAutoShipCartItems;
            case "standard":
                return updatingCartItems;
        }

    }, [shopId])

    const updateItem = async (id) => {
        switch( shopOptions.shopId){
            case "subscriptions":
                return shopStore.updateSubscriptionCartItem( id, { productID: id, cartID: subscriptionUserCartGuid, lang: language, qty: newQty });
                break;
            case "autoship":
                return shopStore.updateAutoShipCartItem(id, { productID: id, cartID: autoShipUserCartGuid, lang: language, qty: newQty });
                break;
            case "standard":
                return shopStore.updateCartItem(id, { productID: id, cartID: userCartGuid, lang: language, qty: newQty });
                break;
        }
    };

    const onDeleteHandler = (id) => {
        commonStore.showConfirm("Are you sure?", "Delete", "Delete"
            , () => {
                deleteItem(id);
            });
    };

    const onUpdateHandler =  async (id) => {
        const result = await updateItem(id);
        onUpdate(item, newQty);
        if (result) {
            setNewQty(null);
        }
    };

    const autoship = shopOptions.shopId === "autoship"

    return (
        <li className={classes.cartCell}>
            <div className={classes.cartCellContent}>
                <img src={imageURL} className={classes.cartImg} alt="img" />
                <div className={classes.cartProductArea}>
                    <div style={{ alignSelf: 'center' }}>
                        <div className={classes.cartProductTitle}>{productName}</div>
                        <div className={classes.cartProductDetails}>
                            {!autoship && <div className={classes.cartProductDetail}>
                                <div className={classes.cartProductHeader}>Format:</div>
                                <div className={classes.cartSmallText}>{isDigital ? 'Digital' : 'Physical'}</div>
                            </div>}
                            <div className={classes.cartProductDetail}>
                                <div className={classes.cartProductHeader} style={{ paddingLeft: (autoship ? 0 : "10px") }}>SKU:</div>
                                <div className={classes.cartSmallText}>{productSKU}</div>
                            </div>
                        </div>
                        <div className={classes.cartDeleteAndQty}>
                            <div className={classes.cartDropdown}>{<QuantityInput qty={newQty || qty} isDigital={isDigital} />}</div>
                            <div className={classes.cartUpdateAndDelete}>
                                {newQty && <>
                                    {updatingItems.find(x=>x===productID)!=null
                                        ? <CircularProgress size={30}/>
                                        : <Button size={"small"} variant={"contained"}
                                                  onClick={() => onUpdateHandler(productID)}
                                                  color={"primary"}>
                                            Update
                                        </Button>}</>}
                                <Button size={"small"} variant={"contained"}
                                    onClick={() => onDeleteHandler(productID)}
                                    color={"secondary"}>
                                    {isItemsDeleting ? <FontAwesomeIcon icon={faSync} size="1x" spin /> : 'Delete'}
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={classes.cartDetailText}>{formatNumber(price, "$")}</div>
                {isMember && <div className={classes.cartDetailText}>{formatNumber(pv)}</div>}
            </div>
        </li>
    );

}
export default observer(CartRow);

import React, {useMemo,} from "react";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from './menu.json';
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import IFrame from "../../components/IFrame";
import LinksMenu from "./LinksMenu";
import Onboard from "../Members/Onboard";
import {joinPath} from "../../utils/helpers";
import {Route} from "react-router";
import SendOut from "../Members/SendOut";
import Policies from "../Profile/Policies";
import Transactions from "../Profile/Transactions";
import SeminarWebinar from "../Events/SeminarWebinar";
import CurrentCustomers from "../Members/CurrentCustomers";
import MyTeam from "../Members/MyTeam";
import MyVolume from "./MyVolume";
import CumulativeCustomerBonus from "./CumulativeCustomerBonus";
import MyProgress from "../Members/MyProgress";
import IncomeAllocation from "../Members/IncomeAllocation";
import MyIncome from "../Members/MyIncome";
import MyTrainingIncome from "../Members/MyTrainingIncome";

function Members ( {parentMatch} ){
    const match = useRouteMatch();
    const MainLinks = useMemo(()=>{
         return  menu.find(x=>x.tag==="members").items
    },[menu])

    return (
        <Container maxWidth={false}>
            <>
                <Switch>
                    <Route path={joinPath(match.url, `/fulloffice/myteam`)} render={props => {
                        return <MyTeam />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/myprogress`)} render={props => {
                        return <MyProgress />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/myincome`)} render={props => {
                        return <MyIncome />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/lifetrainingincome`)} render={props => {
                        return <MyTrainingIncome />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/lifetrainingincome_legacy`)} render={props => {
                        return <IFrame url={"https://appview.mainhomepage.com/loginapp.aspx?pageid=1570&ssl=1"} />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/incomeallocation`)} render={props => {
                        return <IncomeAllocation />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/mycustomers`)} render={props => {
                        return <CurrentCustomers />;
                    }} />
                    <Route path={joinPath(match.url, `/fulloffice/myvolume`)} render={props => {
                        return <MyVolume />;
                    }} />
                    <Route path={joinPath(match.url, `/full office/myteam`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/myteam`)}/>;
                    }} />
                    <Route path={joinPath(match.url, `/full office/income allocation`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/incomeallocation`)}/>;
                    }} />

                    <Route path={joinPath(match.url, `/full office/my progress`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/myprogress`)}/>;
                    }} />

                    <Route path={joinPath(match.url, `/full office/my income`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/myincome`)}/>;
                    }} />
                    <Route path={joinPath(match.url, `/full office/my life training income`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/lifetrainingincome`)}/>;
                    }} />

                    <Route path={joinPath(match.url, `/full office/my volume`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/myvolume`)}/>;
                    }} />

                    <Route path={joinPath(match.url, `/full office/customer bonus tracking`)} render={props => {
                        return <Redirect to={joinPath(match.url, `/fulloffice/customer_bonus_tracking`)}/>;
                    }} />

                    <Route path={joinPath(match.url, `/fulloffice/customer_bonus_tracking`)} render={props => {
                        return <CumulativeCustomerBonus />;
                    }} />

                    <Route path={joinPath(match.url, `/full office/my customers`)} render={props => {
                        return <Redirect to={joinPath(match.url, `fulloffice/mycustomers`)}/>;
                    }} />


                    <Route path={joinPath(match.url, `/marketingsystem/purchase_sendout/:cart_guid`)} render={props => {
                        return <IFrame tag={"marketingsystem/purchase_sendout"} url={`https://shop.lifeinfoapp.com/checkout/index.html?cart_guid=${props.match.params.cart_guid}`} />;
                    }} />
                    <Route path={joinPath(match.url, `/onboard/:tag`)} render={props => {
                        return <Onboard parentMatch={match} navigation={"inplace"} isStarfish
                                        showLogoImage={false}  showBottomTitle={ props.match.params.tag !=="compensation"} />;
                    }} />
                    <Route
                        path={joinPath(match.url, `/mspremium/create_product_sendout`)}
                        render={() => {
                            return <SendOut parentMatch={match} />;
                        }}
                    />
                    <Route  path={joinPath(match.url, `/profile/policies`)}
                           render={() => {  return <Policies />  }} />

                    <Route  path={joinPath(match.url, `/profile/transactions`)}
                           render={() => {  return <Transactions /> }} />

                    }} />
                    <Route path={joinPath(match.url, `/events/seminar_webinar/:year?/:month?`)} render={props => {
                        return <SeminarWebinar />
                    }} />

                    <Route
                        path={joinPath(match.url, `/explorelife/welcome`)}
                        render={() => {
                            return <Onboard parentMatch={match} tagAsParam={"getstarted"} navigation={"inplace"}
                                            showLogoImage={false}  showBottomTitle isStarfish />
                        }}
                    />
                    <Route
                        path={joinPath(match.url, `/explorelife/compensation`)}
                        render={() => {
                            return <Onboard parentMatch={match} tagAsParam={"compensation"} navigation={"inplace"}
                                            showLogoImage={false}  showBottomTitle isStarfish/>
                        }}
                    />
                    <Route render={props => {
                        return  <LinksMenu items={MainLinks} showBack={false}/>
                    }}/>

                </Switch>
            </>
        </Container>
    );
}

export default observer(Members);
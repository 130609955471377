import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import {BottomNavigation, BottomNavigationAction, Box, Container, Divider, Icon, makeStyles} from '@material-ui/core';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import '../../css/owl.scss';
import BalanceNotification from './BalanceNotification';
import ShoppingBoss from '../shoppingboss';
import { useStores } from '../../hooks/use-stores';
import StarfishHeader from '../../components/Header';
import EWallet from '../../containers/Wallet/eWallet';
import LibraryPack from '../../containers/LibraryPack';
import Wallet from './Wallet';
import Profile from '../Profile';
import News from '../News';
import Life from "./Life";
import {Redirect, useRouteMatch} from "react-router-dom";
import NewsPost from '../NewsPost';
import {Alert} from "@material-ui/lab";
import { starFishColor } from "../../components/styles";
import ItemWrapper from "../ItemWrapper";
import MediaDecision from "../MediaDecision";
import VideoModal from "../VideoModal";
import Members from "../RoyalStarfish/Members";
import TopBanner from "../../components/TopBanner";
import MembersRoutes from "../RoyalStarfish/MembersRoutes";
import clsx from "clsx";
import RenewMembershipModal from '../RenewMembershipModal';
import UserProfile from "../RoyalStarfish/Profile";
import menuJson from "../RoyalStarfish/menu.json";
import LinksMenu from "../RoyalStarfish/LinksMenu";
import ShopHome from "../shop";
import ShareMenu from "../RoyalStarfish/Share";
import ShareEnrollment from "../ShareEnrollment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {patchCDN} from "../../utils/helpers";
import Lifeline from "../Lifeline";
import IFrame from "../../components/IFrame";
import {useViewContext} from "../../contexts/useViewContext";
import ErrorMessage from "../Lifeline/CreateVideo/ErrorMessage";
import {prepareBannerItemUrl} from "../../utils/menuUtils";
import MembersDashboard from "../RoyalStarfish/MembersDashboard";
import InfoIcon from "@material-ui/icons/InfoOutlined";
const useStyles = makeStyles((theme) => ({
    container: {
        background: 'black',
        //height: '100vh',
        color: 'white',
        padding: 0,
        margin: 0,
        maxWidth: '100%',
    },
    routeContainer: ({frameless})=> ({
        marginTop: frameless?0:'20px',
        paddingBottom: frameless?0:'75px',
    }),
    contentWrapper: ({frameless})=> ({
        paddingTop: frameless?0:'58px', // compensate for the header height
        paddingLeft: '1px',
        paddingRight: '1px',
    }),
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: '1px',
        paddingRight: '1px'

    },
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        '@media all and (display-mode: standalone)': {
            height: '80px',
            //add padding to the bottom bar
            paddingBottom: '20px'
        }
    },
    banner: {
        display: "block",
        cursor: 'pointer',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            height: 240,
        }
    },
    bannerImage: {
        height: 240,
        width: "auto !important",
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            width: "100vw !important",
        },
    },
    lifeIframe: {
        width: "100%",
        height: "100%",
        border: 0
    },
    giftCardStatsRoot:{
        display:"flex",
        padding:0,
        justifyContent:"center",
        ...starFishColor(theme, "backgroundColor"),
        marginBottom: theme.spacing(3),
        flexDirection:"column",
    },
    giftCardStats:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding: "8px 0",
    },
    left:{
        display:"inline-flex",
        paddingRight: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            paddingRight: 20,
            fontSize:14,
        },
    },
    right:{
        display:"inline-flex",
        paddingLeft: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 20,
            fontSize:14,
        },
    },
    wideIcon:{
        width: "1.5em",
    },
    whiteBanner:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        height:30,
        backgroundColor:"white",
        color:"black",
        fontSize:16,
        fontWeight:"bold",
        padding: theme.spacing(1),
        gap: theme.spacing(2),

    },
}));

const carouselOptions = {
    items: 1,
    nav: false,
    center: true,
    autoWidth: true,
    rewind: true,
    autoplay: false
};

const StarfishContainer = ({ isPhone }) => {
    const { t } = useTranslation();
    const viewContext = useViewContext();
    const { bannerStore, commonStore, authStore, statStore, shoppingBossStore, identityVerificationStore, mediaHandlers, menuStore, libraryStore } = useStores();
    const { bannersLoading } = shoppingBossStore;
    const { giftCardsStats, userBusinessStats } = statStore;
    const { currentUser, subscriptions } = authStore;
    const { identityVerificationStatus } = identityVerificationStore;
    const { menu } = menuStore.mainMenu;
    const { membersBanners } = bannerStore;
    const { detailsLoadingFor } = libraryStore;
    const location = useLocation();
    const isAdminView = location.search.includes('isAdminView')
    const match = useRouteMatch();
    const lifeMatch = useRouteMatch(`${match.url}/life`);
    const starFishHome = useRouteMatch( {path: `/starfish`, exact: true } );
    const starFishMembers = useRouteMatch( {path: `/starfish/members`, exact: true });
    const ref = React.useRef(0);
    const classes = useStyles({frameless: viewContext.frameLess});
    const history = useHistory();
    const [canLoad, setCanLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [media, setMedia] = React.useState(null);
    const showCore4Banner = currentUser.isMember;

    const core4Qualified = subscriptions.isCore4;

    useEffect(() => {
        const receiveMessage = async (event) => {
          // Prevent messages from outer sources
          if (event.origin !== "https://admin.starfishperks.com") {
            return;
          }

          if (event.data === "close") {
            history.push('/adminRedirect?isLogout=true');
          }
        };

        window.addEventListener("message", receiveMessage);

        return () => {
          window.removeEventListener("message", receiveMessage);
        };
      }, []);

    const goHome = useCallback(() => {
        setCurrentPage(0);
        history.push('/starfish');
    }, [currentPage]);

    const goWallet = useCallback(() => {
        setCurrentPage(1);
        history.push('/starfish/wallet');
    }, [currentPage]);

    const goLife = useCallback(() => {
        setCurrentPage(2);
        history.push('/starfish/life');
    }, [currentPage]);

    const goMembers = useCallback(() => {
        setCurrentPage(6);
        history.push('/starfish/members');
    }, [currentPage]);

    const goProfile = useCallback(() => {
        setCurrentPage(3);
        history.push('/starfish/profile');
    }, [currentPage]);

    const goPodcast = useCallback(() => {
        setCurrentPage(4);
        history.push('/starfish/podcast');
    }, [currentPage]);

    const handleLogout = useCallback(() => {
        authStore.logout();
        history.push('/');
    }, []);

    const addMoney = useCallback(() => {
        history.push('/starfish/add_more_money');
    }, []);

    useEffect(() => {
        if ( authStore.isStarfishHomeEnabled ) {
            void shoppingBossStore.getBanners();
            void bannerStore.loadBanners("members")
        }
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const handleMerchantSelectionCanProceed = (merchant) => {
        if(!!merchant?.outofstock) {
            commonStore.showError("Merchant is currently out of stock and unavailable. We are working on getting more inventory. Stay tuned!.", 15000);
            return false;
        }

        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but National Brand Stores is currently unavailable for your account. Please contact customer support for more details.");
            return false;
        } else if (!!isUnverified) {
            commonStore.showError("Before making a purchase, we need you to complete the Identity Verification process.", 15000);
            history.push(`/starfish/add_more_money`);
            return false;
        } else {
            return true;
        }
    };

    const localStore = useLocalObservable(() => ({
        get showStarfishBanner() {
            return  currentUser && ["US", "CA"].includes( currentUser.country) &&  (starFishHome )&& !shoppingBossStore.bannersLoading && this.banners && this.banners.length > 0;
        },
        get banners() {
            if (shoppingBossStore.countryCode === 'CA') {
                return shoppingBossStore.caBanners;
            } else {
                return shoppingBossStore.usBanners;
            }
        }
    }))

    const showPVInfoPopup = ()=>{

        commonStore.showConfirm( <p className={classes.popupText}>{t("starfish:core4.description")} </p>
            , "OK"
            , null
            , null
            , null
            , undefined
            , { hideHeader:true })
    }


    const onClickBanner = (event, merchant, banner) => {
        if ( banner.tag === "marketplaceproducts" ){
            event.preventDefault();
            history.push('/starfish/marketplaceproducts', { backLink: "/starfish" })
            return;
        }
        if ( !merchant || !merchant.id) return;
        event.preventDefault();
        const canProceed = handleMerchantSelectionCanProceed(merchant);
        if (!!canProceed) {
            shoppingBossStore.selectedMerchant = merchant;
            history.push(`/starfish/review`);
        }
    };

    const getPage = (route) => {
        // These are routes that aren't really part of Starfish - give them a page corresponding somewhat
        // the category the belong to
        if (route.includes('news') || route.includes('share')) {
            return 3;
        } else if (route.includes('add_more_money')) {
            return 1;
        } else if (route.includes('wallet')) {
            return 1;
        } else if (route.includes('life')) {
            return 2;
        } else if (route.includes('profile')) {
            return 3;
        } else if (route.includes('podcast')) {
            return 4;
        }  else if (route.includes('members')) {
            return 6;
        } else {
            return 0;
        }
    };

    const playMedia = (url, item) => {
        if (  url.indexOf("player.vimeo.com")>-1)
            setMedia({vimeo:true,  ...item})
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(getPage(location.pathname));
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0); // reset scroll in case returning from another page
        (async () => {
            try {
                await shoppingBossStore.fetchUserBalance();
                setCanLoad(true);
            } catch (e) {
                if (!!e && (typeof e == 'string' ? e : e.message)?.includes('account has not been created')) {
                    return history.push(`/onboarding/shopping_boss`);
                }
            }
        })();
    }, []);


    ///
    //isSuperAppPlus => /royalstarfish
    // isStarfish && !isSuperAppPlus => /starfish
    // !isStarfish   => let the user access Wallet, Life Accelerator (if purchased) and Profile. All other pages should not be accessible
    // isMember => show the Member icon at the bottom bar instead of the Profile
    if ( authStore.currentUser.isSuperAppPlus ) {
         let path = location.pathname.replace("/starfish", "/royalstarfish");
         return <Redirect to={ path || "/royalstarfish"} />
    }

    // if( !authStore.currentUser.isStarfish  && starFishHome ){
    //     return <Redirect to={"/starfish/wallet"} />
    // }

    return (
        <Container className={classes.container}>
            <StarfishHeader
                handleLogout={handleLogout}
                currentUser={authStore?.currentUser}
                isPhone={isPhone}
                prefix={"/starfish"}
            />
            <Container className={classes.contentWrapper} maxWidth={false}>
                <Container maxWidth={lifeMatch  ? false : "md"} className={classes.content}>
                        {authStore.currentUser.isStarfish && <>
                            {(shoppingBossStore.currentPage == 'merchants' || shoppingBossStore.currentPage == 'review') && (currentPage == 0) && <BalanceNotification onAddMoney={addMoney} balance={shoppingBossStore.balance} />}
                            { starFishHome && giftCardsStats &&  <Box display="flex" flexDirection="column" variant="filled" color="info" icon={false} className={classes.giftCardStatsRoot}>
                                <Box display="flex" flexDirection="column" className={classes.giftCardStats} >
                                    <Box display="flex" justifyContent="center">{new Date().toLocaleString( "default", { month: "long" })}</Box>
                                    <Box display="flex">
                                        <div className={classes.left}>{t('starfish:home.transactions')}:
                                            {giftCardsStats.transactionCount} </div>
                                        <Divider orientation="vertical" flexItem />
                                        <div className={classes.right}>{t('starfish:home.perks_earned')}:
                                            ${ (Number( giftCardsStats.perksEarned )||0).toFixed(2) }</div>
                                    </Box>
                                </Box>
                                { showCore4Banner && userBusinessStats &&
                                    <Box display="flex" justifyContent="center" alignItems={"center"} className={classes.whiteBanner}>
                                        <span> {core4Qualified ? " ✅ " : "❌ "}Core 4:</span>
                                        <span>
                                                {(userBusinessStats?.PVPersonal || 0).toFixed(2)}PV
                                            </span>
                                        <InfoIcon onClick={showPVInfoPopup} style={{cursor: "pointer"}}/>
                                    </Box>
                                }
                            </Box>
                            }


                            { starFishHome && authStore.isStarfishHomeEnabled &&
                                <>
                                    { (bannersLoading == null || bannersLoading) || ((shoppingBossStore.loadingMerchants === null || shoppingBossStore.loadingMerchants ) )
                                        ?
                                        <Box style={{height:300, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <CircularProgress />
                                        </Box>
                                        :<>
                                            {currentPage == 0 && <ErrorMessage message={shoppingBossStore.error}/>}
                                            { currentPage == 0 && localStore.banners &&
                                                localStore.banners.length > 0 && <OwlCarousel ref={ref} options={carouselOptions}>
                                                    {localStore.banners.map((banner, i) => {
                                                        let { itemUrl,isLoading}  = prepareBannerItemUrl(banner, true, authStore, detailsLoadingFor);
                                                        return <ItemWrapper  key={`banner-${i}`}  classes={{
                                                            link: classes.link,
                                                        }} item={{...banner, url: itemUrl}} menu={null}
                                                                             baseComponent={"div"}
                                                                             lookupInMenu={false}
                                                                             notMatchedInNewWindow={true}
                                                                             onPlay={playMedia} vertical={false}  notMatchedOnClick={(event) => onClickBanner(event, banner.merchant, banner)}>
                                                            <div className={classes.banner} key={`banner-${i}`} >
                                                                <img className={classes.bannerImage} src={patchCDN(banner.imageURL)} />
                                                            </div>
                                                        </ItemWrapper>;
                                                    })}
                                                </OwlCarousel>
                                            }
                                        </>
                                    }
                                </>}


                            {starFishMembers && membersBanners && membersBanners.length>0 &&
                                <TopBanner banner={membersBanners}
                                           patchUrls={true}
                                           prefix={"/starfish/members"}
                                           menu={menu} match={match} playMedia={playMedia}
                                           detailsLoadingFor={detailsLoadingFor} />
                            }

                        </>}


                    {canLoad &&
                        <Box className={classes.routeContainer}>
                            <RenewMembershipModal isMemberExpired={authStore.currentUser.isMemberExpired} />
                            <EWallet showProgress={false} />
                            <Switch>
                                <Route
                                    path={"/starfish/shop"}
                                    render={(p) => {
                                        return <ShopHome shoppingRoot={'/starfish/shop'} shoppingPath={'/starfish/shop'}
                                                         hideNav={true} currentItem={{rest: "" }}  />
                                    }
                                    }
                                />
                                <Route
                                    path={"/starfish/members/dashboard"}
                                    render={() => {
                                        return <MembersDashboard parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/starfish/wallet"}
                                    render={() => {
                                        return <Wallet parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/starfish/share/shareenrollment"}
                                    component={ShareEnrollment} />

                                <Route
                                    path={"/starfish/share"}
                                    exact
                                    render={() => <LinksMenu parentMatch={match} items={menuJson.find(x=>x.tag==="sharestarfish").items} showBack />}

                                />    <Route
                                    path={"/starfish/lifeproducts"}
                                    exact
                                    render={() => <LinksMenu parentMatch={match} items={menuJson.find(x=>x.tag==="lifeproducts").items} showBack />}

                                />
                                <Route
                                    path={"/starfish/share"}
                                    render={() => <ShareMenu />
                                    }
                                />
                                {MembersRoutes (match, authStore.loginGuid)}

                                <Route
                                    path={"/starfish/members"}
                                    render={() => {
                                        return <Members parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/starfish/marketplaceproducts"}
                                    render={() => {
                                        return <LinksMenu parentMatch={match} items={menuJson.find(x=>x.tag==="marketplaceproducts").items} showBack iFrame />;
                                    }}
                                />
                                <Route
                                    path={"/starfish/podcast"}
                                    render={() => <LibraryPack packIDs={{"en": 18386, "fr": 18489 }} />
                                    }
                                />
                                <Route path={`/starfish/conventionnews`} render={props => {
                                    return <Observer>{() => <IFrame tag={"conventionnews"}></IFrame>}</Observer>;
                                }} />
                                <Route path={`/starfish/lifeline`} component={Lifeline} />

                                <Route
                                    path={"/starfish/news/:id"}
                                    render={() => <NewsPost fromModule={"STARFISH"} />
                                    }
                                />

                                <Route
                                    path={"/starfish/news"}
                                    render={() => <News fromModule={"STARFISH"} />
                                    }
                                />
                                <Route
                                    path={"/starfish/life"}
                                    render={() => <Life />}
                                />
                                <Route
                                    path={"/starfish/profile/"}
                                    render={() => <Redirect to={"/starfish/userprofile/"} />}
                                />
                                <Route
                                    path={"/starfish/userprofile/profile/"}
                                    render={() => <Profile />}
                                />
                                <Route
                                    path={"/starfish/userprofile/"}
                                    render={() => <UserProfile identityVerificationStatus={identityVerificationStatus} />}
                                />
                                <Route
                                    path={"/starfish"}
                                    render={() => {
                                        return currentPage == 0 && <ShoppingBoss fromModule="STARFISH" />;
                                    }}
                                />
                            </Switch>
                        </Box>
                    }
                </Container>
            </Container>

            {!isAdminView && <BottomNavigation
                value={currentPage}
                onChange={(event, newValue) => {
                    setCurrentPage(newValue);
                }}
                showLabels
                className={classes.bottomNav}
            >

                <BottomNavigationAction onClick={goHome} value={0} label={t('starfish:home.home')} icon={<HomeIcon />} />
                <BottomNavigationAction onClick={goWallet} value={1} label={t('starfish:home.wallet')} icon={<AccountBalanceIcon />} />
                {authStore.currentUser.isStarfish
                    &&  <BottomNavigationAction onClick={goPodcast} value={4} label={t('starfish:home.podcast')} icon={<Icon className="fa fa-podcast" />} />}
                <BottomNavigationAction onClick={goLife} value={2} label={t('starfish:home.lifeAccelerator')} icon={<SubscriptionsIcon />} />


                { authStore.currentUser.isMember
                    ?  <BottomNavigationAction onClick={goMembers} value={6} label={t('starfish:home.members')} icon={ <Icon className={ clsx( "fad", "fa-users" ,classes.wideIcon )}/> } />
                    :  <BottomNavigationAction onClick={goProfile} value={3} label={t('starfish:home.profile')} icon={<AccountCircleIcon />} />}

            </BottomNavigation>}

            <VideoModal isPhone={isPhone} />
            <MediaDecision media={media}
                           onOpenBook={mediaHandlers.handleBookOpen}
                           onVideoAlbumOpen={mediaHandlers.handleVideoAlbumOpen}
                           onVideoOpen={mediaHandlers.handleVideoOpen} />
        </Container >
    );
};


export default observer(StarfishContainer);
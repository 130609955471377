import React, {useEffect, useMemo} from "react";
import {AppBar, Box, Button, Container, Paper, Typography} from "@material-ui/core";
import {useFetch} from "use-http";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";
import Toolbar from "@material-ui/core/Toolbar";
import moment from "moment";
import MediaList from "../components/MediaList";
import MediaDecision from "./MediaDecision";
import {useStores} from "../hooks/use-stores";
import AudioPlayer from "../components/AudioPlayer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import VideoModal from "./VideoModal";


function PublicSendout (){
    const {code} =  useParams();
    const theme =  useTheme();
    const { mediaHandlers} = useStores();
    const isPhone = useMediaQuery(theme.breakpoints.down(700));

    const { loading, error, data = [] } = useFetch(`${process.env.REACT_APP_API_URL}/public/sendouts?code=${code}`, {}, [code])


    const title = useMemo( () => {
        if ( data.length>1){
            return data[1][0].title
        }

    }, [data])

    const notes = useMemo( () => {
        if ( data.length>1){
            return data[1][0].notes
        }

    }, [data])

    const senderName = useMemo( () => {
        if ( data.length>1){
            return data[1][0].name
        }

    }, [data])

    const senderEmail = useMemo( () => {
        if ( data.length>1){
            return data[1][0].email
        }

    }, [data])


    const expireDate = useMemo( () => {
        if ( data.length>1){
            return moment( data[1][0].createdDate).add( 3, "days")
        }

    }, [data])

    const items = useMemo( () => {
        if ( data.length>0){


            return data[0].map( x=> {

                let isUrlAbsolute = x.mediaURL && ( x.mediaURL.startsWith("http://") || x.mediaURL.startsWith("https://") )


                const result =  {...x, direct: true,  sendout: true}
                if( !isUrlAbsolute )
                    result.mediaType = "video"

                return result;

            })
        }

    }, [data])


    if ( loading ) return <CircularProgress size={50}/>

    return <Container>
        <Paper>
            <AppBar position="static">
                <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                     <Box paddingLeft={1}>
                        <img src={`/starfish_full.png`}
                             height={27}
                             width={150} />
                    </Box>
                    <Typography variant="h6" align={"center"} >
                        {title}
                    </Typography>
                    <Box  width={150} />

                </Toolbar>
            </AppBar>
            <Box display={"flex"} padding={2} minHeight={500} justifyContent={"space-between"} flexDirection={"column"}>
                <Paper>
                    <Box padding={2}>
                        <Typography variant={"subtitle1"}>Notes:</Typography>
                        <Typography> {notes}</Typography>
                    </Box>
                </Paper>

                <MediaList items={items}
                           onAudioPlay={mediaHandlers.handleAudioPlay}
                           onAudioPause={mediaHandlers.handleAudioPause}
                           onVideoOpen={mediaHandlers.handleVideoOpen}></MediaList>

                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                    <Typography>Sent by:</Typography>
                    <Typography>{senderName}</Typography>
                    <Typography>{senderEmail}</Typography>

                    <Typography variant={"caption"}>This page will expire on {moment(expireDate).format("MM/DD/yyyy")}</Typography>
                    <Typography variant={"caption"}>at {moment(expireDate).format("HH:mm:00 a")}</Typography>
                </Box>
            </Box>
        </Paper>
        {error &&  <Alert  severity={"error"} variant={"filled"}>{error.message}</Alert>}
        <AudioPlayer isPhone={isPhone} enableModeSwitch={false} />
        <VideoModal isPhone={isPhone} />
    </Container>
}


export default PublicSendout